import React, { useContext } from 'react';
import { useTheme, Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography } from '@material-ui/core';
import Player from 'src/@types/Player';
import PlayerStats from 'src/@types/PlayerStats';
import FirebaseContext from '../contexts/FirebaseContext';

const DeletePlayerStatsDialog: React.FunctionComponent<{ player: Player, stats: PlayerStats, onClose(): any, onComplete?(stats: PlayerStats): any, open?: boolean }> = ({ open = false, player, stats, onClose, onComplete }) => {
    const firebase = useContext(FirebaseContext),
        theme = useTheme();

    return (
        <Dialog open={open && !!stats}>
            <DialogTitle>Delete {stats.year} {stats.category} Stats</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle2">Are you sure you want to delete {stats.category} stats for the {stats.year} {stats.team || ''} season?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    variant="contained"
                    style={{ backgroundColor: theme.palette.error.main, color: theme.palette.error.contrastText }}
                    onClick={async () => {
                        await firebase.db.collection('players').doc(player.id).collection('stats').doc(stats.id).delete();

                        if (onComplete) {
                            return onComplete(stats);
                        }
                    }}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeletePlayerStatsDialog;