import React, { FunctionComponent, useState, useContext } from 'react';
import { makeStyles, Typography, Hidden, Link } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import FirebaseContext from '../../contexts/FirebaseContext';
import Player from '../../@types/Player';
import Velocity from '../../@types/Velocity';
import PlayerIdentity from './PlayerIdentity';
import StatsTable from './StatsTable';
import Ranking from './Ranking';
import VelocityCharts from './VelocityCharts';
import BatterDefaultImage from '../../images/batter_default.png';
import PitcherDefaultImage from '../../images/pitcher_default.png';
import EditPictureDialog from './EditPictureDialog';

const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: theme.spacing(4)
    },
    header: {
        backgroundColor: theme.palette.common.black,
        display: 'flex',
        flexFlow: 'row wrap',
        padding: theme.spacing(),
        color: theme.palette.common.white
    },
    pictureContainer: {
        height: 212,
        width: 170,
        border: '1px solid #cccccc',
        marginRight: theme.spacing(2)
    },
    pictureContainerEditable: {
        position: 'relative',
        height: 212,
        width: 170,
        border: '1px solid #cccccc',
        marginRight: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
            opacity: '0.9'
        }
    },
    picture: {
        height: 210,
        width: 168,
    },
    editPictureIcon: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    identity: {
        color: 'inherit',
        flex: 1,
        marginRight: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            borderBottom: `1px solid ${theme.palette.common.white}`,
            paddingBottom: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        borderRight: `1px solid ${theme.palette.common.white}`
    },
    townAndClubContainer: {
        display: 'flex',
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.common.white}`
    },
    club: {
        padding: theme.spacing(),
        paddingTop: theme.spacing(3),
        flex: 1,
        height: 100,
        borderRight: `1px solid ${theme.palette.common.white}`,
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between',
    },
    town: {
        padding: theme.spacing(),
        paddingTop: theme.spacing(3),
        flex: 1,
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between',
        height: 100
    },
    rankingsAndVelocities: {
        width: 400,
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            minWidth: 200,
            marginLeft: theme.spacing(),
            marginRight: theme.spacing()
        }
    },
    rankings: {
        display: 'flex',
        justifyContent: 'center',
        '&>div:first-child': {
            borderRight: `1px solid ${theme.palette.common.white}`
        }
    },
    ranking: {
        flex: 1
    },
    velocities: {
        display: 'flex',
        justifyContent: 'center',
        borderTop: `1px solid ${theme.palette.common.white}`,
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(2),
        '&>div:first-child': {
            borderRight: `1px solid ${theme.palette.common.white}`
        }
    },
    velocity: {
        flex: 1,
        padding: theme.spacing(2)
    },
    stats: {
        marginTop: theme.spacing(2)
    },
    label: {
        color: '#cccccc'
    },
    chart: {
        marginTop: theme.spacing(2)
    }
}));
const PlayerDetail: FunctionComponent<{ player: Player, onUpdate?(player: Player): any }> = ({ player, onUpdate }) => {
    const classes = useStyles(),
        firebase = useContext(FirebaseContext),
        uid = firebase.auth().currentUser ? firebase.auth().currentUser.uid : null,
        [picture, setPicture] = useState(player.picture || (player.positions[0] === 'pitcher' ? PitcherDefaultImage : BatterDefaultImage)),
        [editPicture, setEditPicture] = useState(false),
        [calculatingRankings, setCalculatingRankings] = useState(player.scorePending || false);

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Hidden smDown>
                    <div
                        className={uid === player.createdBy ? classes.pictureContainerEditable : classes.pictureContainer}
                        onClick={() => uid === player.createdBy ? setEditPicture(true) : null}
                    >
                        <img
                            className={classes.picture}
                            src={picture}
                            alt={`${player.firstName} ${player.lastName}`}
                        />
                        {uid === player.createdBy && (
                            <Edit color="primary" className={classes.editPictureIcon} />
                        )}
                    </div>
                    <EditPictureDialog
                        player={player}
                        open={!!editPicture}
                        onClose={() => setEditPicture(false)}
                        onComplete={(newPicture: string) => {
                            setPicture(newPicture);
                            setEditPicture(false);
                            return onUpdate && onUpdate({ ...player, picture: newPicture });
                        }}
                    />
                </Hidden>
                <div className={classes.identity}>
                    <PlayerIdentity
                        player={player}
                        onUpdate={onUpdate}
                    />
                    <div className={classes.townAndClubContainer}>
                        <div className={classes.club}>
                            {player.teamUrl && player.team ? (
                                <Link href={player.teamUrl} target="_blank">
                                   <Typography align="center">{player.team}</Typography>
                                </Link>
                            ) : <Typography color="inherit" align="center">{player.team || 'NA'}</Typography>
                            }
                            <Typography color="inherit" align="center" paragraph variant="caption">TEAM</Typography>
                        </div>
                        <div className={classes.town}>
                            <Typography color="inherit" align="center">{player.city}, {player.state}</Typography>
                            <Typography color="inherit" align="center" paragraph variant="caption">TOWN</Typography>
                        </div>
                    </div>
                </div>
                <div className={classes.rankingsAndVelocities}>
                    <div className={classes.rankings}>
                        <div className={classes.ranking}>
                            <Typography variant="caption" display="block" align="center" color="inherit">NATIONAL RANKING</Typography>
                            <div style={{ display: 'flex' }}>
                                <div className={classes.ranking}>
                                    <Typography variant="h4" align="center" color="inherit">
                                        <Ranking player={player} calculating={calculatingRankings} />
                                    </Typography>
                                    <Typography className={classes.label} variant="caption" display="block" align="center">OVERALL</Typography>
                                </div>
                                <div className={classes.ranking}>
                                    <Typography variant="h4" align="center" color="inherit">
                                        <Ranking player={player} position={player.positions[0]} calculating={calculatingRankings} />
                                    </Typography>
                                    <Typography className={classes.label} variant="caption" display="block" align="center">{player.positions[0].toUpperCase()}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className={classes.ranking}>
                            <Typography variant="caption" display="block" align="center" color="inherit">{player.state} STATE RANKING</Typography>
                            <div style={{ display: 'flex' }}>
                                <div className={classes.ranking}>
                                    <Typography variant="h4" align="center" color="inherit">
                                        <Ranking state={player.state} player={player} calculating={calculatingRankings} />
                                    </Typography>
                                    <Typography className={classes.label} variant="caption" display="block" align="center">OVERALL</Typography>
                                </div>
                                <div className={classes.ranking}>
                                    <Typography variant="h4" align="center" color="inherit">
                                        <Ranking player={player} state={player.state} position={player.positions[0]} calculating={calculatingRankings} />
                                    </Typography>
                                    <Typography className={classes.label} variant="caption" display="block" align="center">{player.positions[0].toUpperCase()}</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.velocities}>
                        <div className={classes.velocity}>
                            <Typography variant="h4" align="center" color="inherit">{player.fastballVelocity || '--'}</Typography>
                            <Typography className={classes.label} variant="caption" display="block" align="center">FB VELO</Typography>
                        </div>
                        <div className={classes.velocity}>
                            <Typography variant="h4" align="center" color="inherit">{player.exitVelocity || '--'}</Typography>
                            <Typography className={classes.label} variant="caption" display="block" align="center">EXIT VELO</Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.chart}>
                <VelocityCharts
                    player={player}
                    onUpdate={(v: Velocity, o: Velocity) => {
                        setCalculatingRankings(true);
                        if (onUpdate && !player[`${v.category}Verified`] && ((!player[v.category] || v.value as number > player[v.category]) || (o && o.id === v.id && o.value === player[v.category]))) {
                            return onUpdate({ ...player, [v.category]: v.value, scorePending: true });
                        }
                    }}
                />
            </div>
            <Hidden smDown>
                <div className={classes.stats}>
                    <StatsTable player={player} category="batting" />
                </div>
            </Hidden>
        </div>
    );
};

export default PlayerDetail;