import React, { useState, useContext } from 'react';
import { makeStyles, Typography, Button } from '@material-ui/core';
import FirebaseContext from '../../contexts/FirebaseContext';
import Player from '../../@types/Player';
import AddEditPlayerDialog from '../../components/AddEditPlayerDialog';

const useStyles = makeStyles(theme => ({
    root: { position: 'relative' },
    editButton: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    nameAndPositions: {
        textAlign: 'center'
    },
    name: {
        flex: 1,
        paddingRight: theme.spacing(2)
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    heightWeight: {
        flex: 1
    },
    vitals: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(1)
    }
}));

const PlayerIdentity = (props: { player: Player, onUpdate?(player:  Player): any }) => {
    const classes = useStyles(),
        firebase = useContext(FirebaseContext),
        [player, setPlayer] = useState(props.player),
        uid = firebase.auth().currentUser ? firebase.auth().currentUser.uid : null,
        [edit, setEdit] = useState(false),
        age = (new Date()).getFullYear() - player.divisionBirthYear;

    return (
        <div className={classes.root}>
            {uid === player.createdBy && (
                <>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.editButton}
                        onClick={() => setEdit(true)}
                    >
                        Edit
                    </Button>
                    <AddEditPlayerDialog
                        player={player}
                        open={edit}
                        onSave={(p: Player) => {
                            setPlayer(p);
                            return props.onUpdate && props.onUpdate(p);
                        }}
                        onClose={() => setEdit(false)}
                    />
                </>
            )}
            <div className={classes.nameAndPositions}>
                <Typography className={classes.uppercase} display="inline" color="inherit" variant="h5">{player.firstName} {player.lastName}</Typography>
                &nbsp;
                <Typography className={classes.uppercase} color="inherit" variant="caption">{age}U - {player.positions.join('/')}</Typography>
            </div>
            <div className={classes.vitals}>
                <div className={classes.heightWeight}>
                    <Typography display="block" align="center" className={classes.uppercase} color="inherit" variant="caption">{Math.floor(player.height / 12)}-{player.height % 12} / {player.weight}</Typography>
                    <Typography display="block" align="center" className={classes.uppercase} color="inherit" variant="caption">Height/Weight</Typography>
                </div>
                <div className={classes.heightWeight}>
                    <Typography display="block" align="center" className={classes.uppercase} color="inherit" variant="caption">{player.bats} / {player.throws}</Typography>
                    <Typography display="block" align="center" className={classes.uppercase} color="inherit" variant="caption">Bats/Throws</Typography>
                </div>
            </div>
        </div>
    );
};

export default PlayerIdentity;
