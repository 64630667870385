import React, { FunctionComponent } from 'react';
import { Card, CardHeader, Avatar, makeStyles, CardActionArea, CardMedia } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import Player from '../../@types/Player';
import PitcherDefaultImage from '../../images/pitcher_default.png';
import BatterDefaultImage from '../../images/batter_default.png';

type CardProps = {
    player: Player,
    className?: string,
    onClick?(p: Player): any
};

const useStyle = makeStyles(theme => ({
    root: {
        width: 240        
    },
    actions: {
        '&:hover svg': {
            display: 'block !important'
        }
    },
    picture: {
        height: 300
    },
    editIcon: {
        position: 'absolute',
        top: theme.spacing(),
        right: theme.spacing(),
        display: 'none'
    }
}));

const PlayerCard: FunctionComponent<CardProps> = ({ player, className, onClick }) => {
    const classes = useStyle(),
        age = (new Date()).getFullYear() - player.divisionBirthYear;

    return (
        <>
            <Card className={className || classes.root}>
                <CardActionArea className={classes.actions} onClick={() => onClick && onClick(player)}>
                    <Edit color="primary" className={classes.editIcon} />
                    <CardMedia
                        className={classes.picture}
                        image={!!player.picture ? player.picture : (player.positions[0] === 'pitcher' ? PitcherDefaultImage : BatterDefaultImage)}
                        title={`${player.firstName} ${player.lastName}`}
                    />
                    <CardHeader
                        avatar={<Avatar>{player.team ? player.team[0] : 'NA'}</Avatar>}
                        title={`${player.firstName} ${player.lastName} - ${player.positions[0]}`}
                        subheader={`${age}U ${player.team ? `- ${player.team}` : ''}`}
                    />
                </CardActionArea>
            </Card>
        </>
    );
};

export default PlayerCard;