import React,  { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import defaultBackground from '../images/background_baseball.jpg';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        margin: theme.spacing(0, -16),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(-2),
            marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.up('lg')]: {
            margin: theme.spacing(0, -32),
            marginBottom: theme.spacing(4),
        },
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      },
}));

const PageHero: FunctionComponent<{ height?: number, background?: string, backgroundPosition?: string, noBackground?: boolean  }> = ({ height = 500, backgroundPosition = 'center', background = defaultBackground, children, noBackground = false }) => {
    const classes = useStyles();

    return (
        <div className={classes.root} style={{ height, backgroundImage: noBackground ? 'none' : `url(${background})`, backgroundPosition }}>
            {children}
        </div>
    );
};

export default PageHero;
