import React, { FunctionComponent, useContext, useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Typography, TextField, Button, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FirebaseContext from '../contexts/FirebaseContext';
import { ReactComponent as GoogleLogo } from '../images/svg/google.svg';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(1),
        width: 350,
        textAlign: 'center'
    },
    googleSignIn: {
        textTransform: 'none'
    },
    or: {
        margin: theme.spacing(2),
        fontWeight: 'normal'
    },
    alert: {
        marginTop: theme.spacing()
    },
    loginLink: {
        marginTop: theme.spacing(2)
    }
}));

const SignUp: FunctionComponent = () => {
    const { state } = useLocation<{ redirect: string }>(),
        history = useHistory(),
        firebase = useContext(FirebaseContext),
        classes = useStyles(),
        [error, setError] = useState<string | null>(null),
        [password, setPassword] = useState(''),
        [email, setEmail] = useState('');

    return (
        <div className={classes.root}>
            <Typography variant="h5" paragraph>New User Sign Up</Typography>
            <TextField
                label="Email"
                size="small"
                variant="outlined"
                margin="normal"
                fullWidth
                placeholder="user@email.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                label="Password"
                size="small"
                margin="normal"
                variant="outlined"
                fullWidth
                placeholder="password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                InputLabelProps={{ shrink: true }}
            />
            <Typography variant="caption" paragraph>By signing up, I agree to PowerStats <Link to="#">Terms of Use</Link></Typography>
            <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                disabled={!password || !email}
                onClick={async () => {
                    try {
                        await firebase.auth().createUserWithEmailAndPassword(email, password);
                        history.push(state?.redirect || '/profile/players');
                    } catch (e) {
                        setError(e.message);
                    }
                }}
            >
                Continue
            </Button>
            {error && <Alert icon={false} className={classes.alert} severity="error">{error}</Alert>}

            <Typography className={classes.or} variant="subtitle2">or</Typography>

            <Button
                variant="outlined"
                fullWidth
                startIcon={<GoogleLogo />}
                onClick={async () => {
                    try {
                        await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
                        history.push(state?.redirect || '/profile/players');
                    } catch (e) {
                        setError(`There was an issue signing in. ${e.message}`);
                    }

                }}
            >
                <Typography className={classes.googleSignIn}>Continue with Google</Typography>
            </Button>
            <Typography className={classes.loginLink} variant="caption" display="block" align="center">Already a Yiketty member? <Link to="/signin">Log In</Link></Typography>
        </div>
    );
};

export default SignUp;
