import React from 'react';
import { makeStyles, Typography, Link as MaterialLink, Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AddPlayer from './images/how_it_works_add_player.png';
import Track from './images/how_it_works_track.png';
import Benchmark from './images/how_it_works_benchmark.png';
import HeroImage from 'src/pages/WhyYiketty/images/firstroundpicks.jpg';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4),
        background: theme.palette.common.white
    },
    header: {
        display: 'flex',
        margin: theme.spacing(4, 0)
    },
    title: {
        margin: theme.spacing(0, -16),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0, -2),
        },
        [theme.breakpoints.up('lg')]: {
            margin: theme.spacing(0, -32),
        },
        padding: theme.spacing(4),
        color: theme.palette.common.white,
        textAlign: 'center',
        backgroundColor: '#c98c32'
    },
    heroImage: {
        marginLeft: theme.spacing(4),
        height: 300
    },
    step: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    right: {
        textAlign: 'right'
    },
    flexContainer: {
        minHeight: 500,
        padding: theme.spacing(4),
        [theme.breakpoints.up('xl')]: {
            padding: theme.spacing(8)
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    picture: {
        height: 350
    },
    highLine: {
        lineHeight: '2.5em'
    }
}));

const HowItWorks = () => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.header}>
                <div className={classes.flexContainer}>
                    <div>
                        <Typography variant="h4" paragraph><strong>Welcome to Yiketty!</strong></Typography>
                        <Typography variant="h6" paragraph>
                            Yiketty was created by a handful of players and their dads to solve a simple need -- create a place where ANY player could track their growth and be able to benchmark their development against players from across the country.
                        </Typography>
                        <Typography variant="h6" paragraph>
                            All of the data is inputted by you, the user -- Yiketty just provides a centralized home so that you can also see how you compare to others. The platform is 100% free and we do not sell or profit from your data in any way.
                        </Typography>
                        <Typography variant="h6"paragraph>
                            We hope you enjoy the site and please send us an email if you have ideas for how we can improve it!
                        </Typography>
                    </div>
                    <Hidden smDown><img className={classes.heroImage} src={HeroImage} alt="Welcome to Yiketty!" /></Hidden>
                </div>
            </div>
            <Typography variant="h4" className={classes.title}>How It Works</Typography>
            <div className={classes.root}>
                <div className={classes.flexContainer}>
                    <div>
                        <Typography color="primary" className={classes.step} variant="h4">Step 1</Typography>
                        <Typography variant="h4" paragraph><strong>Create a player</strong></Typography>    
                        <Typography variant="h6" className={classes.highLine}>
                            Hit <MaterialLink component={Link} to="/signup">SIGN UP</MaterialLink> or <MaterialLink component={Link} to="/profile/players">ADD YOUR PLAYER</MaterialLink> and enter a few pieces of info. It only takes 30 seconds and all info will always remain secure and backed-up.
                        </Typography>
                    </div>
                    <Hidden smDown><img src={AddPlayer} alt="Create a player" /></Hidden>
                </div>
                <div className={classes.flexContainer}>
                    <Hidden smDown><img src={Track} alt="Track development" /></Hidden>
                    <div className={classes.right}>
                        <Typography color="primary" className={classes.step} variant="h4">Step 2</Typography>
                        <Typography variant="h4" paragraph><strong>Track development</strong></Typography>
                        <Typography variant="h6" className={classes.highLine}>
                            Easily add both hitting and pitching stats and key metrics like exit and fastball velocity to track improvements from season to season.
                        </Typography>
                    </div>
                </div>
                <div className={classes.flexContainer}>
                    <div>
                        <Typography color="primary" className={classes.step} variant="h4">Step 3</Typography>
                        <Typography variant="h4" paragraph><strong>Benchmark performance</strong></Typography>
                        <Typography variant="h6" className={classes.highLine}>
                            See how your key metrics compare to other players of similar age. You can also upload a picture or video to verify your exit and fastball velocity and get ranked in your state and nationally!
                        </Typography>
                    </div>
                    <Hidden smDown><img src={Benchmark} alt="Benchmark performance" /></Hidden>
                </div>
            </div>
        </>
    );
};

export default HowItWorks;
