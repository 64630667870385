import React, { FunctionComponent, useState } from 'react';
import { makeStyles, Menu, MenuItem, Button, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Link } from 'react-router-dom'
import usStates from '../../constants/us-states';
import baseballPositions from '../../constants/baseball-positions';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'row wrap',
        marginBottom: theme.spacing(4),
        alignItems: 'center'
    },
    filterItem: {
        display: 'flex',
        margin: theme.spacing(),
        alignItems: 'center'
    },
    button: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(6)
    }
}));

const BaseballFilters: FunctionComponent<{ state: string, age: number, position?: string }> = ({ state, age, position }) => {
    const today = new Date(),
        classes = useStyles(),
        [stateEl, setStateEl] = useState<HTMLElement | null>(null),
        [ageEl, setAgeEl] = useState<HTMLElement | null>(null),
        [positionEl, setPositionEl] = useState<HTMLElement | null>(null);
        
    return (
        <div className={classes.root}>
            <div className={classes.filterItem}>
                <Typography display='inline' variant="subtitle2">{today.getFullYear() + (today.getMonth() > 8 ? 1 : 0)} Division</Typography>
                <Button className={classes.button} variant="contained" onClick={e => setAgeEl(e.currentTarget)}>
                    {age}U
                    <ArrowDropDownIcon />
                </Button>
                <Menu anchorEl={ageEl} open={!!ageEl} onClose={() => setAgeEl(null)}>
                    {[9, 10, 11, 12, 13, 14].map(a => (
                        <MenuItem
                            key={`age_${a}`}
                            component={Link}
                            to={`/baseball/${state}/${a}`}
                            onClick={() => setAgeEl(null)}
                        >
                            {a}U
                        </MenuItem>
                    ))}
                </Menu>
            </div>
            <div className={classes.filterItem}>
                <Typography display='inline' variant="subtitle2">Location</Typography>
                <Button className={classes.button} variant="contained" onClick={e => setStateEl(e.currentTarget)}>
                    {usStates.find(s => s.abbreviation === state)?.name || 'National'}
                    <ArrowDropDownIcon />
                </Button>
                <Menu anchorEl={stateEl} open={!!stateEl} onClose={() => setStateEl(null)}>
                    <MenuItem component={Link} to={`/baseball/national/${age}`} onClick={() => setStateEl(null)}>National</MenuItem>
                    {usStates.map(s => (
                        <MenuItem
                            key={s.abbreviation}
                            component={Link}
                            to={`/baseball/${s.abbreviation}/${age}`}           
                            onClick={() => setStateEl(null)}                 
                        >
                            {s.name}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
            <div className={classes.filterItem}>
                <Typography display="inline" variant="subtitle2">Position</Typography>
                <Button className={classes.button} variant="contained" onClick={e => setPositionEl(e.currentTarget)}>
                    {position || 'All'}
                    <ArrowDropDownIcon />
                </Button>
                <Menu anchorEl={positionEl} open={!!positionEl} onClose={() => setPositionEl(null)}>
                    <MenuItem component={Link} to={`/baseball/${state}/${age}`} onClick={() => setPositionEl(null)}>All</MenuItem>
                    {baseballPositions.map(p => (
                        <MenuItem
                            key={p}
                            component={Link}
                            to={`/baseball/${state}/${age}/${p}`}
                            onClick={() => setPositionEl(null)}
                        >
                            {p}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        </div>
    );
};

export default BaseballFilters;