import React, { useState, useContext, useEffect } from 'react';
import { Tabs, Tab, makeStyles, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Player from 'src/@types/Player';
import Velocity from 'src/@types/Velocity';
import FirebaseTypes from 'firebase/app';
import FirebaseContext from '../../contexts/FirebaseContext';
import VelocityChart from './VelocityChart';
import LogVelocity from './LogVelocity';

const useStyles = makeStyles(() => ({
    chartContainer: {
        height: 350
    },
    tabContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    tabs: {
        flex: 1
    }
}));

const VelocityCharts: React.FC<{ player: Player, onUpdate?(v: Velocity, o?: Velocity): any }> = ({ player, onUpdate }) => {
    const firebase = useContext(FirebaseContext),
        uid = firebase.auth().currentUser ? firebase.auth().currentUser.uid : null,
        [loading, setLoading] = useState(true),
        [velocities, setVelocities] = useState<Velocity[] | null>(null),
        [selected, setSelected] = useState('exitVelocity'),
        [editVelocity, setEditVelocity] = useState<Velocity | null>(null),
        classes = useStyles();

    useEffect(() => {
        if (loading) {
            (async () => {
                try {
                    const snap = await firebase.db.collection('players').doc(player.id).collection('velocities').get();

                    setLoading(false);
                    setVelocities(snap.docs.map((d: FirebaseTypes.firestore.DocumentSnapshot) => d.data() as Velocity));
                } catch (e) {
                    console.log('Velocity fetch error:', e);
                    setLoading(false);
                }
            })();
        }
    }, [loading, setLoading, setVelocities, firebase, player]);

    return (
        <div>
            <div className={classes.tabContainer}>
                <Tabs classes={{ root: classes.tabs }} value={selected} onChange={(e: React.ChangeEvent<{}>, v: string) => setSelected(v)}>
                    <Tab value="exitVelocity" label="Exit Velocity" />
                    <Tab value="fastballVelocity" label="Fastball Velocity" />
                </Tabs>
                {player.createdBy === uid && (
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => setEditVelocity({ category: selected, createdAt: FirebaseTypes.firestore.Timestamp.fromDate(new Date()), value: 0 })}
                        >
                            <AddIcon />Log Velocity
                        </Button>
                        {!!editVelocity && (
                            <LogVelocity
                                open={true}
                                player={player}
                                velocity={editVelocity}
                                onClose={() => {
                                    setEditVelocity(null);
                                }}
                                onSave={(v: Velocity) => {
                                    setVelocities(vs => {
                                        const vIndex = (vs || []).findIndex(ov => ov.id === v.id);
                                        if (vs && vIndex > -1) {
                                            vs[vIndex] = v;
                                            return vs;
                                        } else {
                                            return [...(vs || []), v];
                                        }
                                    });
                                    setSelected(v.category)
                                    setEditVelocity(null);

                                    if (onUpdate) {
                                        if (editVelocity.id) {
                                            return onUpdate(v, editVelocity);
                                        }
                                        return onUpdate(v);
                                    }
                                }}
                                onDelete={(vId: string) => {
                                    setVelocities(vs => {
                                        vs = vs || [];
                                        const vIndex = vs.findIndex(ov => ov.id === vId);
                                        if (vs && vIndex > -1) {
                                            vs.splice(vIndex, 1);
                                        }

                                        return [...vs];
                                    })
                                }}
                            />
                        )}
                    </>
                )}
            </div>
            <div className={classes.chartContainer}>
                {!!velocities && (
                    <VelocityChart
                        key={selected}
                        player={player}
                        category={selected}
                        velocities={velocities?.filter(v => v.category === selected).sort((a: Velocity, b: Velocity) => a.createdAt > b.createdAt ? 1 : -1)}
                        onClick={uid === player.createdBy ? (v: Velocity) => {
                            setEditVelocity(v);
                        } : undefined}
                    />
                )}
            </div>
        </div>
    );
};

export default VelocityCharts;