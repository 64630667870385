import React from 'react';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTheme, useMediaQuery, Theme } from '@material-ui/core';

const BellCurve: React.FunctionComponent<{ age: number, category: string }> = ({ age, category }) => {
    const theme = useTheme(),
        isSmallScreen = useMediaQuery((t: Theme) => t.breakpoints.down('sm')),
        values = [0, 1, 8, 32, 40, 32, 8, 1, 0],
        labels = [];

    console.log('is small', isSmallScreen);
    
    for (let i = 0; i < 7; i++) {
        labels.push(30 + ((age - 9) * 5) + (i * 5));
    }

    return (
        <Line
            plugins={[ChartDataLabels]}
            options={{
                height: isSmallScreen ? 300 : 600,
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    point: {
                        radius: 0
                    }
                },
                title: {
                    display: true,
                    text: category === 'exit' ? 'Hitting Exit Velocity Percentile Rankings' : 'Fastball Velocity Percentile Rankings',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontSize: 18,
                    fontColor: theme.palette.text.primary,
                    fontStyle: 'bold',
                    padding: 32
                },
                layout: {
                    padding: {
                        left: theme.spacing(4),
                        right: theme.spacing(4),
                        top: theme.spacing(2),
                        bottom: theme.spacing(2)
                    }
                },
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        display: false
                    }],
                    xAxes: [{
                        scaleLabel: {
                            display: 'true',
                            labelString: 'Miles Per Hour'
                        },
                        ticks: {
                            fontSize: 18,
                            fontStyle: 'bold',
                            fontColor: theme.palette.text.primary
                        }
                    }]
                }
            }}
            data={{
                labels: ['', ...labels, ''],
                datasets: [{
                    label: `${category === 'exit' ? 'Exit' : 'Fastball'} Velocity`,
                    data: values,
                    fill: false,
                    pointHitRadius: 0,
                    pointBorderWidth: 0,
                    borderWidth: 6,
                    borderColor: theme.palette.primary.main,
                    datalabels: {
                        display: false
                    }
                }, {
                    label: 'Percentiles',
                    data: values.map((v, i) => i  < 2 || i > 6 ? v * 2 : v / 2),
                    fill: false,
                    showLine: false,
                    pointBorderWidth: 0,
                    pointHitRadius: 0,
                    borderWidth: 0,
                    datalabels: {
                        formatter: (v: string, context: any) => {
                            if (context.dataIndex > 0 && context.dataIndex < 8) {
                                return `${([1, 2, 16, 50, 84, 98, 99])[context.dataIndex - 1]}%`
                            }

                            return null;
                        },
                        font: {
                            family: '"Roboto", "Helvetica", "Arial", sans-serif',
                            size: isSmallScreen ? 12 : 18,
                            weight: 'bold'
                        }
                    }
                }]
            }}
        />
    )
};

export default BellCurve;