import React, { useContext, useState } from 'react';
import { Typography, TextField, Button, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FirebaseContext from '../contexts/FirebaseContext';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(1),
        width: 300,
        textAlign: 'center'
    },
    alert: {
        marginTop: theme.spacing()
    }
}));

const ForgotPassword = () => {
    const classes = useStyles(),
        [email, setEmail] = useState(''),
        [sent, setSent] = useState(false),
        [error, setError] = useState(''),
        firebase = useContext(FirebaseContext);

    return (
        <div className={classes.root}>
            <Typography variant="h5" paragraph>Forgot Password</Typography>
            {sent ? (
                <Alert severity="success">An password reset link wwas sent to {email}.</Alert>
            ) : (
                <>
                    <Typography variant="body2"  paragraph>Enter your email address and we will send you a password reset link.</Typography>
                    <TextField
                        label="Email"
                        size="small"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        placeholder="user@email.com"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ type: 'email' }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        fullWidth
                        disabled={!email}
                        onClick={async () => {
                            try {
                                await firebase.auth().sendPasswordResetEmail(email);
                                setSent(true);
                            } catch (e) {
                                setError(e.message);
                            }
                        }}
                    >
                        Continue
                    </Button>
                    {error && <Alert icon={false} className={classes.alert} severity="error">{error}</Alert>}
                </>
            )}
        </div>
    );
};

export default ForgotPassword;
