import React, { FunctionComponent, useState, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, LinearProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FirebaseApp from 'firebase/app';
import Player from 'src/@types/Player';
import { DropzoneArea } from 'material-ui-dropzone';
import FirebaseContext from '../../contexts/FirebaseContext';

const EditPictureDialog: FunctionComponent<{ open?: boolean, player: Player, onClose?(): any, onComplete?(picture: string): any }> = ({ open = false, player, onClose, onComplete }) => {
    const [picture, setPicture] = useState<File | null>(null),
        [progress, setProgress] = useState(0),
        [error, setError] = useState<string | null>(null),
        firebase = useContext(FirebaseContext);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Update Picture for {player.firstName} {player.lastName}</DialogTitle>
            <DialogContent>
                {progress === 0 ? (
                    <DropzoneArea
                        dropzoneText="Drag and drop a picture here or click"
                        acceptedFiles={['image/*']}
                        filesLimit={1}
                        onChange={files => setPicture(files.length ? files[0] : null)}
                    />
                ) : (
                    <LinearProgress variant="determinate" value={progress} />
                )}
                {error && <Alert severity="error">{error}</Alert>}
            </DialogContent>
            {!progress && (
                <DialogActions>
                    <Button
                        onClick={() => {
                            setPicture(null);
                            return onClose && onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!picture && !progress}
                        onClick={async () => {
                            if(picture) {
                                console.log(picture);
                                const fileNameSplit = picture?.name.split('.'),
                                    ref = firebase.storage.ref(`player_pictures/${player.id}.${fileNameSplit[fileNameSplit.length - 1]}`),
                                    uploadTask = ref.put(picture);

                                uploadTask.on('state_changed', (snap: FirebaseApp.storage.UploadTaskSnapshot) => {
                                    setProgress((snap.bytesTransferred / snap.totalBytes) * 100);
                                }, (error: Error) => {
                                    setError(error.message);
                                    setProgress(0);
                                }, async () => {
                                    const url = await uploadTask.snapshot.ref.getDownloadURL();

                                    await firebase.db.collection('players').doc(player.id).set({ picture: url }, { merge: true });
                                    setProgress(0);
                                    setPicture(null);
                                    return onComplete && onComplete(url);
                                });
                            }
                        }}
                    >
                        Submit
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    )
};

export default EditPictureDialog;