import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import FirebaseApp from 'firebase/app';
import { Theme, Typography, makeStyles, Button, Dialog, DialogTitle, DialogContent, IconButton, Card, CardHeader, CardMedia, CardActionArea, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import Player from '../@types/Player';
import FirebaseContext from '../contexts/FirebaseContext';
import PlayerCard from '../components/PlayerCard/PlayerCard';
import AddEditPlayerDialog from '../components/AddEditPlayerDialog';
import PageHero from '../components/PageHero';
import PlayerDetail from '../components/PlayerDetail/PlayerDetail';
import BaseballTeamImage from '../images/baseball_team.jpg';
import BatterDefaultImage from '../images/batter_default.png';

const useStyle = makeStyles(theme => ({
    playerList: {
        margin: theme.spacing(1, -1),
        display: 'flex',
        flexFlow: 'row wrap'
    },
    playerCard: {
        margin: theme.spacing(),
        width: 240,
        '&:hover > ': {
           
        }
    },
    addButton: {
        marginTop: theme.spacing(2)
    },
    dialogTitle: {
        padding: 0,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    actions: {
        justifyContent: 'flex-end'
    },
    picture: {
        height: 300
    }
}));

const MyPlayers: FunctionComponent = () => {
    const classes = useStyle(),
        isSmallScreen = useMediaQuery((t : Theme) => t.breakpoints.down('sm')),
        firebase = useContext(FirebaseContext),
        [addOpen, setAddOpen] = useState(false),
        [detail, setDetail] = useState<Player | null>(null),
        uid = firebase.auth().currentUser ? firebase.auth().currentUser.uid : null,
        [players, setPlayers] = useState<Array<Player> | null>(null),
        [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (uid) {
            (async () => {
                try {
                    const snapshots = await firebase.db.collection('players').where('createdBy', '==', uid).get();
                    const newPlayers: Array<Player> = [];
                    snapshots.forEach((p: FirebaseApp.firestore.DocumentSnapshot) => {
                        if (p.data()) {
                            newPlayers.push(p.data() as Player);
                        }
                    });
                    setPlayers(newPlayers);
                } catch (e) {
                    console.log('Fetch players error', e);
                    setError('Unable to fetch players');
                }
            })();
        }
    }, [uid, setPlayers, setError, firebase]);

    if (!uid) {
        return <Typography>Please sign in</Typography>;
    }
    if (error) {
        return <Alert severity="error">{error}</Alert>
    }
    if (players) {
        return (
            <>
                <PageHero height={300} background={BaseballTeamImage} />
                <Typography variant="h5">My Players</Typography>
                {players.length === 0 && (
                    <>
                        <Typography variant="caption" paragraph>You haven't added any players yet</Typography>
                        <Card style={{ width: 240 }}>
                            <CardActionArea onClick={() => setAddOpen(true)}>
                                <CardMedia
                                    className={classes.picture}
                                    image={BatterDefaultImage}
                                />
                                <CardHeader
                                    style={{ textAlign: 'center' }}
                                    title={<Button size="small" variant="contained" color="primary">Add Player</Button>}
                                />
                            </CardActionArea>
                        </Card>
                    </>
                )}
                {players.length > 0  && (
                    <>
                        <div className={classes.playerList}>
                            {players.sort((a: Player, b: Player) => a.divisionBirthYear > b.divisionBirthYear ? 1 : -1).map((p: Player) => (
                                <PlayerCard
                                    key={p.id}
                                    player={p}
                                    className={classes.playerCard}
                                    onClick={p => setDetail(p)}
                                />
                            ))}
                        </div>
                        <Button className={classes.addButton} variant="contained" color="primary" onClick={() => setAddOpen(true)}>Add Player</Button>
                    </>
                )}
                <Dialog fullScreen={isSmallScreen} maxWidth="lg" fullWidth open={!!detail} onClose={() => setDetail(null)}>
                    <DialogTitle className={classes.dialogTitle}>
                        <IconButton aria-label="close" onClick={() => setDetail(null)}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {!!detail && (
                            <PlayerDetail
                                player={detail}
                                onUpdate={(updated: Player) => {
                                    setDetail(updated);
                                    setPlayers(playerList => {
                                        if (playerList) {
                                            const index = playerList.findIndex(player => player.id === updated.id);
                                            if (index > -1) {
                                                playerList[index] = updated;
                                            }
                                        }

                                        return !!playerList ? [...playerList] : null;
                                    });
                                }}
                            />
                        )}
                    </DialogContent>
                </Dialog>
                <AddEditPlayerDialog
                    open={addOpen}
                    onSave={(p: Player) => {
                        setPlayers([p, ...players]);
                        setDetail(p);
                    }}
                    onClose={() => setAddOpen(false)}
                />
            </>
        );
    }

    return <></>;
};

export default MyPlayers;