import React, { FunctionComponent, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem,
    Typography, FormControl, InputLabel, Button, Checkbox, ListItemText } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FirebaseApp from 'firebase/app';
import FirebaseContext from '../contexts/FirebaseContext';
import Player from '../@types/Player';
import usStates from '../constants/us-states';
import baseballPositions from '../constants/baseball-positions';

type DialogProps = {
    player?: Player
    open: boolean
    onClose(): any
    onSave?(p: Player): any
}

const useStyle = makeStyles((theme: Theme) => ({
    row: {
        display: 'flex',
        flexFlow: 'row wrap'
    },
    formControl: {
        minWidth: 150,
        margin: theme.spacing(),
        flex: 1
    },
    deleteButton: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.error.main
        }
    }
}));

const calculateDivisionBirthYear = (birthday: Date) => {
    const todayYear = (new Date()).getFullYear(),
        maxBirthYear = todayYear - 9,
        minBirthYear = todayYear - 14;

    let divisionBirthYear = birthday.getFullYear();

    if (birthday.getMonth() > 3) {
        divisionBirthYear++;
    }

    return Math.min(Math.max(divisionBirthYear, minBirthYear), maxBirthYear);
};


const AddPlayerDialog: FunctionComponent<DialogProps> = ({ player, open, onClose, onSave }) => {
    const classes = useStyle(),
        today = new Date(),
        history = useHistory(),
        firebase = useContext(FirebaseContext),
        [newPlayer, setPlayer] = useState<any>(player || {}),
        [heightFt, setHeightFt] = useState<number>(player ? Math.floor(player.height / 12) : 0),
        [heightIn, setHeightIn] = useState<number>(player ? player.height % 12 : 0),
        [deleting, setDeleting] = useState(false),
        [primaryPosition, setPrimaryPosition] = useState<string>(player && player.positions && player.positions.length ? player.positions[0] : ''),
        [busy, setBusy] = useState<boolean>(false),
        years = [];

    for (let i = 0; i < 14; i++) {
        years.push(today.getFullYear() + i);
    }

    return (
        <Dialog open={open}>
            <DialogTitle>{player ? 'Edit' : 'Add'} Player</DialogTitle>
            <DialogContent>
                <div className={classes.row}>
                    <TextField required className={classes.formControl} label="First Name" value={newPlayer.firstName || ''} onChange={e => setPlayer({ ...newPlayer, firstName: e.target.value })} />
                    <TextField required className={classes.formControl} label="Last Name" value={newPlayer.lastName || ''} onChange={e => setPlayer({ ...newPlayer, lastName: e.target.value })} />
                </div>
                <div className={classes.row}>
                    <TextField required className={classes.formControl} label="City" value={newPlayer.city || ''} onChange={e => setPlayer({ ...newPlayer, city: e.target.value })} />
                    <FormControl className={classes.formControl}>
                        <InputLabel required id="states_label">State</InputLabel>
                        <Select required labelId="state_label" value={newPlayer.state || ''} onChange={e => setPlayer({ ...newPlayer, state: e.target.value })}>
                            {usStates.map(s => <MenuItem key={s.abbreviation} value={s.abbreviation}>{s.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <TextField
                        required
                        className={classes.formControl}
                        label="Height (ft)"
                        value={heightFt || ''}
                        onChange={e => setHeightFt(parseInt(e.target.value, 10))}
                    />
                    <TextField
                        required
                        className={classes.formControl}
                        label="Height (in)"
                        value={heightIn || 0}
                        onChange={e => setHeightIn(parseInt(e.target.value, 10))}
                    />
                    <TextField required className={classes.formControl} label="Weight (lbs)" value={newPlayer.weight || ''} onChange={e => setPlayer({ ...newPlayer, weight: e.target.value })} />
                </div>
                <div className={classes.row}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            className={classes.formControl}
                            disableToolbar
                            variant="inline"
                            value={newPlayer.birthday?.toDate() || null}
                            format="MM/dd/yyyy"
                            onChange={(date: Date | null) => setPlayer({ ...newPlayer, birthday: date ? FirebaseApp.firestore.Timestamp.fromDate(date) : null, divisionBirthYear: date ? calculateDivisionBirthYear(date) : null })}
                            label="Birthday"
                            required
                        />
                    </MuiPickersUtilsProvider>
                    <FormControl className={classes.formControl}>
                        <InputLabel required id="divisionBirthYear_label">{today.getFullYear()} Season Competition Age</InputLabel>
                        <Select required labelId="divisionBirthYear_label" value={newPlayer.divisionBirthYear || ''} onChange={e => setPlayer({ ...newPlayer, divisionBirthYear: e.target.value })}>
                            {newPlayer.birthday && [9, 10, 11, 12, 13, 14].map(y => (
                                <MenuItem key={`year_${y}`} value={today.getFullYear() - y}>{y}U</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.formControl}>
                        <InputLabel required id="throws_label">Throws</InputLabel>
                        <Select required labelId="throws_label" value={newPlayer.throws || ''} onChange={e => setPlayer({ ...newPlayer, throws: e.target.value })}>
                            {['R', 'L'].map(t => <MenuItem key={`throws_${t}`} value={t}>{t}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel required id="bats_label">Bats</InputLabel>
                        <Select required labelId="bats_label" value={newPlayer.bats || ''} onChange={e => setPlayer({ ...newPlayer, bats: e.target.value })}>
                            {['R', 'L', 'S'].map(t => <MenuItem key={`bats_${t}`}  value={t}>{t}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <FormControl className={classes.formControl}>
                        <InputLabel required id="pos_label">Primary Position</InputLabel>
                        <Select style={{ textTransform: 'capitalize' }} required labelId="pos_label" value={primaryPosition || null} onChange={e => setPrimaryPosition(e.target.value as string)}>
                                {baseballPositions.map(pos => <MenuItem style={{ textTransform: 'capitalize' }} key={pos} value={pos}>{pos}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="pos_label">Other Positions</InputLabel>
                        <Select
                            style={{ textTransform: 'capitalize' }}
                            multiple
                            labelId="pos_label"
                            value={(newPlayer.positions || []).filter((pos: string) => pos !== primaryPosition)}
                            onChange={e => setPlayer({ ...newPlayer, positions: e.target.value as string[] })}
                            renderValue={selected => (selected as string[]).join(', ')}
                        >
                            {baseballPositions.filter(pos => pos !== primaryPosition)
                                .map(pos => (
                                    <MenuItem key={pos} value={pos}>
                                        <Checkbox checked={(newPlayer.positions || []).indexOf(pos) > -1} />
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={pos} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <TextField className={classes.formControl} label="Team Name" value={newPlayer.team || ''} onChange={e => setPlayer({ ...newPlayer, team: e.target.value })} />
                    <TextField className={classes.formControl} label="Team URL" value={newPlayer.teamUrl || ''} onChange={e => setPlayer({ ...newPlayer, teamUrl: e.target.value })} />
                </div>
            </DialogContent>
            <DialogActions>
                {!!player && !deleting && (
                    <div style={{ flex: 1 }}>
                        <Button
                            size="small"
                            onClick={() => setDeleting(true)}
                        >
                            Delete Player
                        </Button>
                    </div>
                )}
                {!!player && deleting ? (
                    <>
                        <div style={{ flex: 1 }}>
                            <Typography variant="subtitle2">Are you sure you want to delete {player.firstName}?</Typography>
                        </div>
                        <Button onClick={() => setDeleting(false)} size="small">Cancel</Button>
                        <Button
                            size="small"
                            className={classes.deleteButton}
                            onClick={async () => {
                                await firebase.db.collection('players').doc(player.id).delete();
                                history.go(0);
                            }}
                        >
                            Delete
                        </Button>
                    </>
                ) : (
                    <>
                        <Button onClick={onClose} size="small">Cancel</Button>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            disabled={busy}
                            onClick={async () => {
                                setBusy(true);
                                try {
                                    const collection = firebase.db.collection('players'),
                                        doc = player ? collection.doc(player.id) : collection.doc();
                                    await doc.set({
                                        id: doc.id,
                                        createdBy: player ? player.createdBy : firebase.auth().currentUser.uid,
                                        createdAt: player ? player.createdAt : new Date(),
                                        firstName: newPlayer.firstName,
                                        lastName: newPlayer.lastName,
                                        city: newPlayer.city,
                                        state: newPlayer.state,
                                        birthday: newPlayer.birthday,
                                        divisionBirthYear: newPlayer.divisionBirthYear,
                                        positions: Array.from(new Set([primaryPosition, ...(newPlayer.positions || [])].filter((pos, i) => baseballPositions.indexOf(pos) > -1))),
                                        bats: newPlayer.bats,
                                        throws: newPlayer.throws,
                                        height: heightFt * 12 + heightIn,
                                        weight: parseInt(newPlayer.weight, 10),
                                        team: newPlayer.team || null,
                                        teamUrl: newPlayer.teamUrl || null
                                    }, { merge: true });
                                    const saved = await doc.get();
                                    setBusy(false);

                                    if (onSave) {
                                        onSave(saved.data());
                                    }
                                    return onClose();
                                } catch (e) {
                                    console.error('Save error', e);
                                    setBusy(false);
                                }
                            }}
                        >
                            Save
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default AddPlayerDialog;