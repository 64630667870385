import React, { FunctionComponent, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, IconButton, Typography, MenuItem, Menu } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import FirebaseContext from '../contexts/FirebaseContext';

type UserIdentityProps = {
    authenticated: boolean
};

const UserIdentity: FunctionComponent<UserIdentityProps> = ({ authenticated }) => {
    const firebase = useContext(FirebaseContext),
        user = firebase.auth().currentUser,
        [menuAnchor, setMenuAnchor] = useState<HTMLElement|null>(null);

    if (authenticated && firebase.auth().currentUser) {
        return (
            <>
                <Typography>Welcome {user.displayName ? user.displayName.split(' ')[0] : user.email.split('@')[0]}!</Typography>
                <IconButton onClick={e => setMenuAnchor(e.currentTarget)}>
                    <AccountCircle />
                </IconButton>
                <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={() => setMenuAnchor(null)}>
                    <MenuItem component={Link} to="/profile/players" onClick={() => setMenuAnchor(null)}>My Players</MenuItem>
                    <MenuItem onClick={() => firebase.auth().signOut()}>Logout</MenuItem>
                </Menu>
            </>
        );
    }

    // return <Button onClick={() => firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())}>Sign In</Button>;
    return (
        <>
            <Button component={Link} to="/signin">Sign In</Button>
            <Button component={Link} to="/profile/players" variant="outlined" color="primary">Add Your Player</Button>
        </>
    );
};

export default UserIdentity;