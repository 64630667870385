import React, { useState } from 'react';
import { Typography, Button, TextField, makeStyles, useMediaQuery, Theme } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PageHero from 'src/components/PageHero';
import HeroImage from 'src/images/batter_8.jpg';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4)
    },
    formContainer: {
        margin: theme.spacing(0, -2),
        padding: theme.spacing(2)
    },
    textField: {
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0)
        }
    }
}));

const ContactPage = () => {
    const classes = useStyles(),
        isSmallScreen = useMediaQuery((t: Theme) => t.breakpoints.down('sm')),
        [submitted, setSubmitted] = useState(false),
        [name, setName] = useState(''),
        [email, setEmail] = useState(''),
        [phone, setPhone] = useState(''),
        [message, setMessage] = useState('');

    return (
        <>
            <PageHero height={300} background={HeroImage} />
            <div className={classes.root}>
                <Typography variant="h4" align="center" paragraph><strong>Have a question or an idea to improve the platform?</strong></Typography>
                <Typography variant="h4" align="center" paragraph><strong>We'd love to hear from you!</strong></Typography>

                {submitted ? (
                    <Alert variant="standard" severity="success" icon={false}>Your message has been sent. Thank you for your feedback!</Alert>
                ) : (
                    <>
                        <div className={classes.formContainer}>
                            <TextField className={classes.textField} label="Name" required value={name} onChange={e => setName(e.target.value)} fullWidth={isSmallScreen} />
                            <TextField className={classes.textField} label="Email" required value={email} onChange={e => setEmail(e.target.value)} fullWidth={isSmallScreen} />
                            <TextField className={classes.textField} label="Phone (optional)" value={phone} onChange={e => setPhone(e.target.value)} fullWidth={isSmallScreen} />
                            <TextField className={classes.textField} label="Message" variant="outlined" required value={message} onChange={e => setMessage(e.target.value)} fullWidth multiline rows={4} />
                        </div>
                        <Button
                            disabled={!name || !email || !message}
                            variant="contained"
                            color="primary"
                            onClick={async () => {
                                const url = 'https://us-central1-yiketty.cloudfunctions.net/email-contactForm',
                                    options = {
                                        method: 'POST',
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json;charset=UTF-8'
                                        },
                                        body: JSON.stringify({ name, email, phone, message })
                                    };

                                await fetch(url, options);

                                return setSubmitted(true);
                            }}
                        >
                            Submit
                        </Button>
                    </>
                )}
            </div>
        </>
    );
};

export default ContactPage;