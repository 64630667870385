import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
    apiKey: "AIzaSyDqmNSdeF2tSesn7wmhtyn5xIxAvMnFR_4",
    authDomain: "yiketty.firebaseapp.com",
    projectId: "yiketty",
    storageBucket: "yiketty.appspot.com",
    messagingSenderId: "765037352399",
    appId: "1:765037352399:web:bc97c6248d0894c04e2eba",
    measurementId: "G-JXQXRBXMYD"
};

class Firebase {
    auth;
    db;
    storage;

    constructor() {
        app.initializeApp(config);
        this.auth = app.auth;
        this.db = app.firestore();
        this.storage = app.storage();
    }
}

export default Firebase;
