import React, { useContext, useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom';
import { AppBar, Toolbar, List, ListItem, Hidden, IconButton, SwipeableDrawer, ListItemText } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Menu as MenuIcon } from '@material-ui/icons';
import FirebaseApp from 'firebase/app';
import FirebaseContext from './contexts/FirebaseContext';
import UserIdentity from './components/UserIdentity';
import Logo from './images/logo.jpg';
import Baseball from './pages/Baseball';
import MyPlayers from './pages/MyPlayers';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import WhyYiketty from './pages/WhyYiketty/WhyYiketty';
import HowItWorks from './pages/HowItWorks/HowItWorks';
import Player from './pages/Player';
import Contact from './pages/Contact';
import Benchmarks from './pages/Benchmarks/Benchmarks';
import ForgotPassword from './pages/ForgotPassword';
import PageFooter from './components/PageFooter/PageFooter';
import ScrollToTop from './components/ScrollToTop';

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    height: theme.spacing(6),
    margin: theme.spacing(1, 4),
    marginLeft: theme.spacing(1),
  },
  nav: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  navItem: {
    whiteSpace: 'nowrap',
    width: 'auto',
    textTransform: 'none',
    color: theme.palette.common.black
  },
  mobileNav: {
    flex: 1,
    textAlign: 'right'
  },
  main: {
    padding: theme.spacing(2, 16),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(2, 32),
      paddingBottom: theme.spacing(8),
    },
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(8)
    },
    marginTop: theme.spacing(6),
  }
}));

const App = () => {
  const classes = useStyles(),
    firebase = useContext(FirebaseContext),
    [authState, setAuthState] = useState<string>('loading'),
    [showNav, setShowNav] = useState(false);

  useEffect(() => {
    const unlisten = firebase.auth().onAuthStateChanged((authUser: FirebaseApp.User) => {
        authUser ? setAuthState('auth') : setAuthState('unauth');
    });

    return () => {
        unlisten();
    };
  }, [firebase]);

  if (authState === 'loading') {
    return null;
  }

  return (
    <Router>
      <div className="App">
        <AppBar color="inherit">
          <Toolbar>
            <Link to="/"><img className={classes.logo} src={Logo} alt="Yiketty" /></Link>
            <Hidden smDown>
              <List className={classes.nav}>
                {/*<ListItem className={classes.navItem} component={Link} to="/why">Why Yiketty</ListItem>*/}
                <ListItem className={classes.navItem} component={Link} to="/how">How It Works</ListItem>
                <ListItem className={classes.navItem} component={Link} to="/benchmarks">Benchmarks</ListItem>
                <ListItem className={classes.navItem} component={Link} to="/contact">Contact Us</ListItem>
              </List>
              <UserIdentity authenticated={authState === 'auth'} />
            </Hidden>
            <Hidden mdUp>
              <div className={classes.mobileNav}>
                <IconButton onClick={() => setShowNav(true)}>
                  <MenuIcon />
                </IconButton>
                <SwipeableDrawer anchor="right" open={showNav} onClose={() => setShowNav(false)} onOpen={() => setShowNav(true)}>
                  <List>
                    <ListItem component={Link} onClick={() => setShowNav(false)} to="/how">
                      <ListItemText primary="How It Works" />
                    </ListItem>
                    <ListItem component={Link} onClick={() => setShowNav(false)} to="/benchmarks">
                      <ListItemText primary="Benchmarks" />
                    </ListItem>
                    <ListItem component={Link} onClick={() => setShowNav(false)} to="/contact">
                      <ListItemText primary="Contact Us" />
                    </ListItem>
                    {authState === 'auth' ? (
                      <>
                        <ListItem component={Link} onClick={() => setShowNav(false)} to="/profile/players">
                          <ListItemText primary="My Players" />
                        </ListItem>
                        <ListItem
                          onClick={() => {
                            setShowNav(false);
                            firebase.auth().signOut();
                          }}
                        >
                          <ListItemText primary="Logout" />
                        </ListItem>
                      </>
                    ) : (
                      <ListItem component={Link} onClick={() => setShowNav(false)} to="/signin">
                        <ListItemText primary="Login / Signup" />
                      </ListItem>
                    )}
                  </List>
                </SwipeableDrawer>
              </div>
            </Hidden>
          </Toolbar>
        </AppBar>
        <main className={classes.main}>
          <ScrollToTop />
          <Route exact path="/">
            <Redirect to={`/baseball/national`} />
          </Route>
          <Route path="/why"><WhyYiketty /></Route>
          <Route path="/how"><HowItWorks /></Route>
          <Route path="/benchmarks/:age?"><Benchmarks /></Route>
          <Route path="/contact"><Contact key={Math.random()} /></Route>
          <Route path="/baseball/:state?/:age?/:position?">
            <Baseball />
          </Route>
          <Route path="/players/:playerId">
            <Player />
          </Route>
          <Route path="/profile/players">
            {authState === 'auth'
              ? <MyPlayers />
              : <Redirect to={{ pathname: '/signin', state: { redirect: '/profile/players' } }} />}
          </Route>
          <Route path="/signin">
            {authState === 'auth'
              ? <Redirect to="/profile/players" />
              : <SignIn />}
          </Route>
          <Route path="/signup">
          {authState === 'auth'
              ? <Redirect to="/profile/players" />
              : <SignUp />}
          </Route>
          <Route path="/forgot-password"><ForgotPassword /></Route>
        </main>
      </div>
      <div className="Footer">
        <PageFooter />
      </div>
    </Router>
  );
};

export default App;
