import React, { FunctionComponent, useContext, useState, useEffect } from 'react';
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, Tabs, Tab, makeStyles, Button, IconButton, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MoreVert';
import Alert from '@material-ui/lab/Alert';
import FirebaseApp from 'firebase/app';
import FirebaseContext from '../../contexts/FirebaseContext';
import Player from '../../@types/Player';
import PlayerStats from '../../@types/PlayerStats';
import DeletePlayerStatsDialog from '../DeletePlayerStatsDialog';
import UpdatePlayerStats from './UpdatePlayerStats';

type StatField = {
    key: string,
    header: string,
    format?(v: number | string): string | JSX.Element,
    sticky?: boolean
}

type StatsFields = {
    [index: string]: StatField[],
    batting: StatField[],
    pitching: StatField[]
}

const statsFields: StatsFields = {
    batting: [
        { key: 'year', header: 'SEASON', format: (v: string) => <strong>{v}</strong>, sticky: true },
        { key: 'team', header: 'TEAM', sticky: true },
        { key: 'gamesPlayed', header: 'G' },
        { key: 'atBats', header: 'AB' },
        { key: 'runs', header: 'R' },
        { key: 'hits', header: 'H' },
        { key: 'doubles', header: '2B' },
        { key: 'triples', header: '3B' },
        { key: 'homeRuns', header: 'HR' },
        { key: 'rbis', header: 'RBI' },
        { key: 'walks', header: 'BB' },
        { key: 'hitByPitch', header: 'HBP' },
        { key: 'sacrifice', header: 'SAC' },
        { key: 'strikeOuts', header: 'SO' },
        { key: 'stolenBases', header: 'SB' },
        { key: 'caughtStealing', header: 'CS' },
        { key: 'average', header: 'AVG', format: (v: number) => v.toFixed(3) },
        { key: 'onBase', header: 'OBP', format: (v: number) => v.toFixed(3) },
        { key: 'slugging', header: 'SLUG', format: (v: number) => v.toFixed(3) },
        { key: 'ops', header: 'OPS', format: (v: number) => v.toFixed(3) }
    ],
    pitching: [
        { key: 'year', header: 'SEASON', format: (v: string) => <strong>{v}</strong>, sticky: true },
        { key: 'team', header: 'TEAM', sticky: true },
        { key: 'gamesPlayed', header: 'G' },
        { key: 'gamesStarted', header: 'GS' },
        { key: 'wins', header: 'W' },
        { key: 'losses', header: 'L' },
        { key: 'saves', header: 'SV' },
        // { key: 'saveOpp', header: 'SVO' },
        { key: 'innings', header: 'IP', format: (v: number) => v.toFixed(1) },
        { key: 'hits', header: 'H' },
        { key: 'runs', header: 'R' },
        { key: 'earnedRuns', header: 'ER' },
        { key: 'hitByPitch', header: 'HBP' },
        { key: 'walks', header: 'BB' },
        { key: 'strikeOuts', header: 'SO' },
        { key: 'era', header: 'ERA', format: (v: number) => v.toFixed(3) },
        { key: 'whip', header: 'WHIP', format: (v: number) => v.toFixed(3) }
    ]
};

const useStyles = makeStyles(theme => ({
    tabContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    tabs: {
        flex: 1
    },
    table: {
        overflowX: 'auto'
    },
    topMargin: {
        marginTop: theme.spacing(2)
    },
    sticky: {
        position: 'sticky',
        whiteSpace: 'nowrap'
    }
}));

const StatsMenu: FunctionComponent<{ stats: PlayerStats, player: Player, onEdit(s: PlayerStats): any, onDelete(s: PlayerStats): any }> = ({ stats, player, onEdit, onDelete }) => {
    const [editting, setEditting] = useState(false),
        [deleting, setDeleting] = useState(false),
        [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    return (
        <>
            <IconButton onClick={e => setAnchorEl(e.currentTarget)}><MenuIcon /></IconButton>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
                <MenuItem
                    onClick={() => {
                        setEditting(true);
                        setAnchorEl(null);
                    }}
                >
                    Edit
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setDeleting(true);
                        setAnchorEl(null);
                    }}
                >
                    Delete
                </MenuItem>
            </Menu>

            {deleting && (
                <DeletePlayerStatsDialog
                    open={true}
                    player={player}
                    stats={stats}
                    onClose={() => setDeleting(false)}
                    onComplete={(s: PlayerStats) => {
                        setDeleting(false);
                        return onDelete(s);
                    }}
                />
            )}
            {editting && (
                <UpdatePlayerStats
                    player={player}
                    stats={stats}
                    onClose={() => setEditting(false)} 
                    onComplete={updated => {
                        setEditting(false);
                        return onEdit(updated);
                    }}
                />
            )}
        </>
    );
};

const StatsTable: FunctionComponent<{ player: Player, category: string }> = ({ player }) => {
    const firebase = useContext(FirebaseContext),
        uid = firebase.auth().currentUser ? firebase.auth().currentUser.uid : null,
        classes = useStyles(),
        [addStats, setAddStats] = useState(false),
        [stats, setStats] = useState<PlayerStats[] | null>(),
        [error, setError] = useState<string | null>(null),
        [category, setCategory] = useState<string>('batting');

    useEffect(() => {
        if (!stats) {
            (async () => {
                try {
                    const snapshots = await firebase.db.collection('players').doc(player.id).collection('stats').get(),
                        data: PlayerStats[] = [];
                    snapshots.forEach((s: FirebaseApp.firestore.DocumentSnapshot) => {
                        data.push(s.data() as PlayerStats);
                    });

                    setStats(data);
                } catch(e) {
                    console.log('Fetch player stats error', e);
                    setError('There was a problem loading player stats');
                    setStats([]);
                }
            })();
        }
    }, [stats, setStats, firebase, player]);

    if (stats) {
        return (
            <div>
                <div className={classes.tabContainer}>
                    <Tabs classes={{ root: classes.tabs }} value={category} onChange={(e: React.ChangeEvent<{}>, v: string) => setCategory(v)}>
                        <Tab value="batting" label="Batting Stats" />
                        {player.positions.indexOf('pitcher') > -1 && <Tab value="pitching" label="Pitching Stats" />}
                    </Tabs>
                </div>
                {stats.filter(s => s.category === category).length > 0 ? (
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {statsFields[category].map((f: StatField) => <TableCell className={f.sticky ? classes.sticky : undefined} key={f.key}>{f.header}</TableCell>)}
                                {uid === player.createdBy && <TableCell></TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stats.filter(s => s.category === category).sort((a, b) => (a.year || 0) - (b.year || 0)).map(s => (
                                <TableRow key={s.year}>
                                    {statsFields[category].map((f: StatField) => (
                                        <TableCell key={`${s.year}_${f.key}`} className={f.sticky ? classes.sticky : undefined}>
                                            {s[f.key] && f.format ? f.format(s[f.key] as number) : s[f.key] || 0}
                                        </TableCell>
                                    ))}
                                    {uid === player.createdBy && (
                                        <TableCell>
                                            <StatsMenu
                                                stats={s}
                                                player={player}
                                                onDelete={ds => setStats(os => {
                                                    os = os || [];
                                                    const i = os.findIndex(osi => osi.id === ds.id);
                                                    if (i > -1) {
                                                        os.splice(i, 1);
                                                    }
                                                    return [...os];
                                                })}
                                                onEdit={updated => setStats(oldStats => {
                                                    oldStats = oldStats || [];
                                                    const i = oldStats.findIndex(st => st.id === updated.id);
                                                    if (i > -1) {
                                                        oldStats[i] = updated;
                                                    } else {
                                                        oldStats.push(updated);
                                                    }
                                                    return [ ...oldStats ];
                                                })}
                                            />
                                            
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Typography className={classes.topMargin} variant="subtitle2">There are no {category} stats logged yet.</Typography>
                )}
                {uid === player.createdBy && (
                    <>
                        <Button
                            className={classes.topMargin}
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={() => setAddStats(true)}
                        >
                            Add Season
                        </Button>
                        {!!addStats && (
                            <UpdatePlayerStats
                                player={player}
                                stats={{
                                    id: firebase.db.collection('players').doc(player.id).collection('stats').doc().id,
                                    year: (new Date()).getFullYear(),
                                    team: player.team,
                                    category
                                }}
                                onClose={() => setAddStats(false)} 
                                onComplete={updated => {
                                    setAddStats(false);
                                    return setStats(os => {
                                        os = os || [];
                                        os.push(updated);
                                        return [ ...os ];
                                    });
                                }}
                            />
                        )}
                    </>
                )}
                {error && <Alert severity="error">{error}</Alert>}
            </div>
        );
    }

    return <></>;
};

export default StatsTable;