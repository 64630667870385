import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Menu, MenuItem, Typography, makeStyles } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import BellCurve from './BellCurve';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4)
    },
    selectors: {
        display: 'flex',
        flexFlow: 'row wrap',
        marginBottom: theme.spacing(4),
        alignItems: 'center'
    },
    filterItem: {
        display: 'flex',
        margin: theme.spacing(),
        alignItems: 'center'
    },
    button: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(6)
    },
    chartContainer: {
        marginBottom: theme.spacing(4),
        height: 650,
        [theme.breakpoints.down('sm')]: {
            height: 350
        }
    }
}));

const Benchmarks = () => {
    const { age = '9' } = useParams<{ age?: string | undefined, category?: string | undefined, position?: string | undefined }>(),
    classes = useStyles(),
    [ageEl, setAgeEl] = useState<HTMLElement | null>(null),
    today = new Date();
    
    return (
        <div className={classes.root}>
            <Typography variant="h4" paragraph align="center"><strong>Velocity Benchmarks By Age Division</strong></Typography>
            <div className={classes.selectors}>
                <div className={classes.filterItem}>
                    <Typography display='inline' variant="subtitle2">{today.getFullYear() + (today.getMonth() > 8 ? 1 : 0)} Division</Typography>
                    <Button className={classes.button} variant="contained" onClick={e => setAgeEl(e.currentTarget)}>
                        {age}U
                        <ArrowDropDown />
                    </Button>
                    <Menu anchorEl={ageEl} open={!!ageEl} onClose={() => setAgeEl(null)}>
                        {[9, 10, 11, 12, 13, 14].map(a => (
                            <MenuItem
                                key={`age_${a}`}
                                component={Link}
                                to={`/benchmarks/${a}`}
                                onClick={() => setAgeEl(null)}
                            >
                                {a}U
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            </div>
            <div className={classes.chartContainer}>
                <BellCurve age={parseInt(age)} category="exit" />
            </div>
            <div className={classes.chartContainer}>
                <BellCurve age={parseInt(age)} category="fastball" />
            </div>
        </div>
    )
};

export default Benchmarks;