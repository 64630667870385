import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Image1 from './images/party.jpg';
import Image2 from './images/batter.jpg';
import Image3 from './images/firstroundpicks.jpg';
import Image4 from './images/tracking.png';

const useStyles = makeStyles(theme => ({
    flexContainer: {
        height: 500,
        margin: theme.spacing(0, -16),
        padding: theme.spacing(4, 16),
        [theme.breakpoints.up('lg')]: {
            margin: theme.spacing(0, -32),
            padding: theme.spacing(4, 32)
        },
        display: 'flex',
        alignItems: 'center',
        '&:nth-child(even)': {
            backgroundColor: '#F0DFBD'
        },
        justifyContent: 'space-between',
        '&>div': {
            padding: theme.spacing(2)
        }
    },
    picture: {
        height: 350
    }
}));

const WhyYiketty = () => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.flexContainer}>
                <div>
                    <Typography variant="h4" paragraph><strong>It started at an early age</strong></Typography>
                    <Typography variant="h6">
                        <p>Maybe it was the pinata crushed at a friend's backyard birthday party.</p>
                        <p>Or maybe a dodgeball that was chucked surprisingly hard.</p>
                        <p>Whatever it was, a baseball bat and glove were destined to be part of the future.</p>
                    </Typography>
                </div>
                <img className={classes.picture} src={Image1} alt="It started at an early age" />
            </div>
            <div className={classes.flexContainer}>
                <img className={classes.picture} src={Image2} alt="As seasons passed..." />
                <div>
                    <Typography variant="h4" paragraph><strong>As seasons passed</strong></Typography>
                    <Typography variant="h6">
                        <p>The skills and potential became more evident.</p>
                        <p>He hit the ball harder. He threw the ball on a line. He made plays not normal for his age.</p>
                        <p>Even other parents began to notice it.</p>
                    </Typography>
                </div>
            </div>
            <div className={classes.flexContainer}>
                <div>
                    <Typography variant="h4" paragraph><strong>And you begin to wonder</strong></Typography>
                    <Typography variant="h6">
                        <p>Just  how good is he?</p>
                        <p>Could he hang with the best players from other towns or even states?</p>
                        <p>Could he possibly develop into the next superstar?</p>
                    </Typography>
                </div>
                <img className={classes.picture} src={Image3} alt="And you begin to wonder..." />
            </div>
            <div className={classes.flexContainer}>
                <img className={classes.picture} src={Image4} alt="Tracking stats and key metrics became the norm." />
                <div>
                    <Typography variant="h4" paragraph><strong>Tracking stats and key metrics became the norm</strong></Typography>
                    <Typography variant="h6">
                        <p>But you had no place to compare them with other similar aged players from across the country.</p>
                        <p>You had no way to see how good he actually was, or had the potential to become.</p>
                        <p>Until now...</p>
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default WhyYiketty;