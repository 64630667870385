import React, { FunctionComponent, useContext, useState } from 'react';
import { Table, TableBody, TableHead, TableRow, TableCell, TextField, Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import FirebaseContext from '../../contexts/FirebaseContext';
import Player from '../../@types/Player';
import PlayerStats from '../../@types/PlayerStats';

type StatField = {
    key: string,
    header: string,
    editable?: boolean,
    onSave?(v: string): any
};

const statFields: { [index: string]: StatField[] } = {
    batting: [
        { key: 'gamesPlayed', header: 'G', editable: true },
        { key: 'atBats', header: 'AB', editable: true },
        { key: 'runs', header: 'R', editable: true },
        { key: 'hits', header: 'H', editable: true },
        { key: 'doubles', header: '2B', editable: true },
        { key: 'triples', header: '3B', editable: true },
        { key: 'homeRuns', header: 'HR', editable: true },
        { key: 'rbis', header: 'RBI', editable: true },
        { key: 'walks', header: 'BB', editable: true },
        { key: 'hitByPitch', header: 'HBP', editable: true },
        { key: 'sacrifice', header: 'SAC', editable: true },
        { key: 'strikeOuts', header: 'SO', editable: true },
        { key: 'stolenBases', header: 'SB', editable: true },
        { key: 'caughtStealing', header: 'CS', editable: true }
    ],
    pitching: [
        { key: 'gamesPlayed', header: 'G', editable: true },
        { key: 'gamesStarted', header: 'GS', editable: true },
        { key: 'wins', header: 'W', editable: true },
        { key: 'losses', header: 'L', editable: true },
        { key: 'saves', header: 'SV', editable: true },
        // { key: 'saveOpp', header: 'SVO', editable: true  },
        { key: 'innings', header: 'IP', editable: true, onSave: v => parseFloat(v) },
        { key: 'hits', header: 'H', editable: true  },
        { key: 'runs', header: 'R', editable: true  },
        { key: 'earnedRuns', header: 'ER', editable: true  },
        { key: 'hitByPitch', header: 'HBP', editable: true  },
        { key: 'walks', header: 'BB', editable: true  },
        { key: 'strikeOuts', header: 'SO', editable: true  }
    ]
};

const INNINGS_PER_GAME = 6;

const calculateBattingStats = (stats: PlayerStats) => {
    const atBats = stats.atBats as number || 0,
        hits = stats.hits as number || 0,
        doubles = stats.doubles as number || 0,
        triples = stats.triples as number || 0,
        homeRuns = stats.homeRuns as number || 0,
        walks = stats.walks as number || 0,
        hitByPitch = stats.hitByPitch as number || 0,
        sacrifice = stats.sacrifice as number || 0,
        totalAtBat = atBats + walks + hitByPitch,
        onBaseTotal = walks + hits + hitByPitch + sacrifice,
        average = parseFloat((hits > 0 ? hits / atBats : 0).toFixed(3)),
        onBase = parseFloat((onBaseTotal > 0 && totalAtBat > 0 ? onBaseTotal / totalAtBat : 0).toFixed(3)),
        slugging = parseFloat((hits > 0 ? ((doubles * 2) + (triples * 3) + (homeRuns * 4) + (hits - doubles - triples - homeRuns)) / atBats : 0).toFixed(3)),
        ops = onBase + slugging;

    return {
        ...stats,
        average,
        onBase,
        slugging,
        ops
    };
};

const calculatePitchingStats = (stats: PlayerStats) => {
    const fullInnings = Math.round(stats?.innings as number || 0),
        partialInnings = (((stats?.innings as number || 0) % 1) * 10) / 3,
        innings = fullInnings + partialInnings,
        earnedRuns = stats.earnedRuns as number || 0,
        hits = stats.hits as number || 0,
        walks = stats.walks as number || 0,
        era = Math.round((earnedRuns / (innings / INNINGS_PER_GAME)) * 100) / 100,
        whip = Math.round((hits + walks) / innings * 100) / 100;

    return {
        ...stats,
        whip,
        era
    };
};

const UpdatePlayerStats: FunctionComponent<{ player: Player, stats: PlayerStats, onClose(): any, onComplete?(stats: PlayerStats): any }> = ({ player, stats, onClose, onComplete }) => {
    const firebase = useContext(FirebaseContext),
        [newStats, setNewStats] = useState<({ [index:string]: any })>(stats);

    return (
        <Dialog open={true} onClose={onClose} maxWidth="lg">
            <DialogContent>
                <TextField
                    style={{ marginRight: 8 }}
                    label="Year"
                    inputProps={{ type: 'number', min: 2000 }}
                    value={newStats?.year || ''}
                    onChange={e => setNewStats(s => ({ ...s, year: e.target.value }))}
                />
                <TextField label="Team" value={newStats?.team || ''} onChange={e => setNewStats(s => ({ ...s, team: e.target.value }))} />
                <Table>
                    <TableHead>
                        <TableRow>
                            {statFields[stats.category as string].map(field => (
                                <TableCell style={{ minWidth: 48 }} key={field.key}>{field.header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {statFields[stats.category as string].map(field => (
                                <TableCell key={`${stats.year}_${field.key}`}>
                                    {field.editable ? (
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            value={!!newStats && newStats[field.key] ? newStats[field.key] : ''}
                                            onChange={e => setNewStats(s => ({ ...s, [field.key]: e.target.value }))}
                                        />
                                    ) : <TableCell><strong>{stats[field.key] || null}</strong></TableCell>}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button size="small" onClick={onClose}>Cancel</Button>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                        if (!!newStats) {
                            const updatedStats = statFields[stats.category as string].reduce((s: {[index: string]: any}, f) => {
                                if (newStats[f.key] && f.onSave) {
                                    s[f.key] = f.onSave(newStats[f.key] as string);
                                } else {
                                    s[f.key] = parseInt(newStats[f.key] as string);
                                }
                                return s;
                            }, {});

                            updatedStats.team = newStats.team;
                            updatedStats.year = parseInt(newStats.year);

                            await firebase.db.collection('players').doc(player.id).collection('stats').doc(stats.id).set({ ...stats, ...updatedStats }, { merge: true });
                            if (onComplete) {
                                let returnStats;
                                if (stats.category === 'batting') {
                                    returnStats = calculateBattingStats({ ...stats, ...updatedStats });
                                } else {
                                    returnStats = calculatePitchingStats({ ...stats, ...updatedStats });
                                }

                                return onComplete(returnStats);
                            }
                        }
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>                 
    );
};

export default UpdatePlayerStats;