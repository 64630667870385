import React, { FunctionComponent, useEffect, useState, useContext } from 'react';
import { Theme, Typography, Table, TableHead, TableBody, TableRow, TableCell, Dialog, DialogContent, Link, makeStyles, Button, Hidden, DialogTitle, IconButton, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { useParams, useHistory, Link as RouterLink } from 'react-router-dom';
import FirebaseApp from 'firebase/app';
import FirebaseContext from '../contexts/FirebaseContext';
import Player from '../@types/Player';
import usStates from '../constants/us-states';
import PlayerDetail from '../components/PlayerDetail/PlayerDetail';
import BaseballFilters from '../components/BaseballFilters/BaseballFilters';
import PageHero from '../components/PageHero';
import Background from '../images/batandglove.jpg';

const useStyles = makeStyles(theme => ({
    alert: {
        margin: theme.spacing(2, 0),
        justifyContent: 'center'
    },
    hero: {
        alignSelf: 'flex-start',
        padding: theme.spacing(4),
        paddingLeft: theme.spacing(16),
        width: '50%',
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(2),
            width: 'auto'
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: theme.spacing(32),
        },
    },
    tagline: {
        marginBottom: theme.spacing(3)
    },
    heroPrimary: {},
    table: {
        tableLayout: 'fixed',
        minHeight: 200
    },
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
    },
    numberCell: {
        fontSize: '1.0rem',
        width: 75,
        borderBottom: 'none'
    },
    capitalize: {
        textTransform: 'capitalize',
        borderBottom: 'none'
    },
    cell: {
        borderBottom: 'none',
        fontSize: '1.0rem'
    },
    dialogTitle: {
        padding: 0,
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

const StyledCell: FunctionComponent<{ className?: string }> = ({ children, className }) => {
    const classes = useStyles();

    return <TableCell classes={{ root: className || classes.cell }}>{children}</TableCell>;
};

const Home: FunctionComponent = () => {
    const { age = '10', state = 'national', position } = useParams<{ age?: string | undefined, state?: string | undefined, position?: string | undefined }>(),
        isSmallScreen = useMediaQuery((t: Theme) => t.breakpoints.down('sm')),
        classes = useStyles(),
        history = useHistory(),
        firebase = useContext(FirebaseContext),
        [detail, setDetail] = useState<Player | null>(null),
        [list, setList] = useState<Player[]>(),
        [error, setError] = useState<string | null>(null),
        // [sortOrder, setSortOrder] = useState<string>('asc'),
        // [sortField, setSortField] = useState<string>('rank'),
        fullState = usStates.find(s => s.abbreviation === state);

    if (!fullState && state !== 'national') {
        history.push(`/baseball/national/${age}`);
    }

    useEffect(() => {
        (async () => {
            try {
                const limit = firebase.auth().currentUser ? 100 : 10,
                    today = new Date();

                let query = firebase.db.collection('players').where(firebase.auth().currentUser && position && position === 'pitcher' ? 'fastballVelocityVerified' : 'exitVelocityVerified', '==', true);

                if (state !== 'national' && firebase.auth().currentUser) {
                    console.log('adding state', state);
                    query = query.where('state', '==', state.toUpperCase());
                }

                console.log('adding divisionBirthYear', today.getFullYear() - parseInt(age, 10));
                query = query.where('divisionBirthYear', '==', (today.getFullYear() + (today.getMonth() > 8 ? 1 : 0)) - parseInt(age, 10));

                if (position && firebase.auth().currentUser) {
                    console.log('adding position', position);
                    query = query.where('positions', 'array-contains', position);
                }

                const snapshots = await query.orderBy('rankingScore', 'desc').limit(limit).get(),
                    playerList: Array<Player> = [];

                snapshots.forEach((p: FirebaseApp.firestore.DocumentSnapshot) => {
                    if (p.data()) {
                        playerList.push(p.data() as Player);
                    }
                });
                setList(playerList);
            } catch (e) {
                console.log('Fetch players error', e);
                setError('Unable to fetch players');
            }
        })();
    }, [age, state, position, setList, firebase]);

    return (
        <>
            <PageHero background={Background} backgroundPosition="left">
                <div className={classes.hero}>
                    <div className={classes.tagline}>
                        <Typography className={classes.heroPrimary} variant="h3" paragraph><strong>From tee to draftee</strong></Typography>
                        <Typography variant="h5">Add stats and key metrics to track a player's progress and compare them to the best youth baseball talent in America</Typography>
                    </div>
                    <div>
                        <Button variant="contained" color="primary" size="large" component={RouterLink} to="/profile/players">Add Your Player For Free</Button>
                    </div>
                </div>
            </PageHero>
            <BaseballFilters age={parseInt(age, 10)} state={firebase.auth().currentUser ? state : 'national'} position={firebase.auth().currentUser ? position : undefined} />
            {!firebase.auth().currentUser && (
                <Alert icon={false} classes={{ root: classes.alert }} severity="warning">
                    Only displaying top 10 nationally ranked players at each age. Please <RouterLink to="/signin">Sign In</RouterLink> to view the rest of the list.
                </Alert>
            )}
            <Typography variant="h5">
                {firebase.auth().currentUser && fullState && state !== 'national' ? `${fullState.name } ` : 'National '} {age}U
                Player Rankings
            </Typography>
            <Table classes={{ root: classes.table }}>
                <TableHead>
                    <TableRow>
                        <StyledCell className={classes.numberCell}>Rank</StyledCell>
                        <StyledCell>Name</StyledCell>
                        <Hidden smDown>
                            <StyledCell>Positions</StyledCell>
                            <StyledCell>Hometown</StyledCell>
                            <StyledCell>Team</StyledCell>
                        </Hidden>
                        <StyledCell className={classes.numberCell}>Exit Velo</StyledCell>
                        <StyledCell className={classes.numberCell}>FB Velo</StyledCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!!list && list.map((p, i)=> (
                        <TableRow key={p.id} classes={{ root: classes.tableRow }}>
                            <StyledCell>{i+1}</StyledCell>
                            <StyledCell>
                                <Link style={{ cursor: 'pointer' }} onClick={() => setDetail(p)}>{p.firstName} {p.lastName}</Link>
                                <Typography variant="caption" display="block">{Math.floor(p.height / 12)}-{p.height % 12}, {p.weight}lbs, {p.bats}/{p.throws}</Typography>
                            </StyledCell>
                            <Hidden smDown>
                                <StyledCell className={classes.capitalize}>{p.positions ? p.positions.join(', ') : ''}</StyledCell>
                                <StyledCell>{p.city}, {p.state}</StyledCell>
                                <StyledCell>{p.team || p.city}</StyledCell>
                            </Hidden>
                            <StyledCell>{p.exitVelocity}</StyledCell>
                            <StyledCell>{p.fastballVelocity}</StyledCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {error && <Alert severity="error">{error}</Alert>}

            <Dialog fullScreen={isSmallScreen} maxWidth="lg" fullWidth open={!!detail} onClose={() => setDetail(null)}>
                <DialogTitle className={classes.dialogTitle}>
                    <IconButton aria-label="close" onClick={() => setDetail(null)}>
                    <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>{!!detail && <PlayerDetail player={detail} />}</DialogContent>
            </Dialog>
        </>
    );
};

export default Home;