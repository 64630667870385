import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListSubheader, ListItemText, makeStyles, Button, Typography, Link as StyledLink } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: { },
    addPlayer: {
        backgroundColor: '#c98c32',
        color: theme.palette.common.white,
        textAlign: 'center',
        padding: theme.spacing(6, 4)
    },
    flex: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2, 16),
        [theme.breakpoints.up('xl')]: {
          padding: theme.spacing(2, 32),
          paddingBottom: theme.spacing(8),
        },
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(2),
          paddingBottom: theme.spacing(8)
        },
    },
    flexColumn: {
        marginRight: theme.spacing(12),
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(4)
        },
        '&:last-child': {
            marginRight: 0
        }
    }
}));

const PageFooter = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.addPlayer}>
                <Typography variant="h5" color="inherit" paragraph>Track stats. Compare metrics. Discover potential.</Typography>
                <Button variant="contained" color="secondary" component={Link} to="/profile/players">Add Player Now</Button>
            </div>
            <div className={classes.flex}>
                <List className={classes.flexColumn}>
                    <ListSubheader disableGutters><Typography variant="h6">Rankings</Typography></ListSubheader>
                    <ListItemText><StyledLink component={Link} to="/baseball/national/9">9U National</StyledLink></ListItemText>
                    <ListItemText><StyledLink component={Link} to="/baseball/national/10">10U National</StyledLink></ListItemText>
                    <ListItemText><StyledLink component={Link} to="/baseball/national/11">11U National</StyledLink></ListItemText>
                    <ListItemText><StyledLink component={Link} to="/baseball/national/12">12U National</StyledLink></ListItemText>
                    <ListItemText><StyledLink component={Link} to="/baseball/national/13">13U National</StyledLink></ListItemText>
                    <ListItemText><StyledLink component={Link} to="/baseball/national/14">14U National</StyledLink></ListItemText>
                </List>
                <List className={classes.flexColumn}>
                    <ListSubheader disableGutters><Typography variant="h6">Yiketty</Typography></ListSubheader>
                    <ListItemText><StyledLink component={Link} to="/how">How It Works</StyledLink></ListItemText>
                    <ListItemText><StyledLink component={Link} to="/benchmarks">Benchmarks</StyledLink></ListItemText>
                    <ListItemText><StyledLink component={Link} to="/profile/players">Add Your Player</StyledLink></ListItemText>
                </List>
                <List className={classes.flexColumn}>
                    <ListSubheader disableGutters><Typography variant="h6">Contact Us</Typography></ListSubheader>
                    <ListItemText>Please direct all inquiries to <StyledLink href="mailto:info@yiketty.com">info@yiketty.com</StyledLink></ListItemText>
                </List>
            </div>
            <Typography variant="caption" align="center" display="block">&copy;2021 Yiketty.com</Typography>
        </div>
    );
};

export default PageFooter;