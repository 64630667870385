import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, createMuiTheme, ThemeProvider } from '@material-ui/core';
import Firebase from './Firebase';
import FirebaseContext from './contexts/FirebaseContext';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#713016',
      contrastText: '#ffffff'
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={new Firebase()}>
      <CssBaseline>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </CssBaseline>
    </FirebaseContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
