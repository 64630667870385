import React, { useContext, useState, useEffect } from 'react';
import FirebaseTypes from 'firebase/app';
import FirebaseContext from '../../contexts/FirebaseContext';
import Player from 'src/@types/Player';
import { CircularProgress } from '@material-ui/core';

const Ranking: React.FunctionComponent<{ player: Player, state?: string, position?: string, calculating?: boolean }> = ({ player, state, position, calculating }) => {
    const firebase = useContext(FirebaseContext),
        { divisionBirthYear, rankingScore } = player,
        verifiedField = position && position === 'pitcher' ? 'fastballVelocityVerified' : 'exitVelocityVerified',
        verified = player[verifiedField],
        SCORE_AGGREGATE_BUCKET_SIZE = 5,
        [showCalculating, setShowCalculating] = useState(calculating || false),
        [loading, setLoading] = useState(!!rankingScore),
        [tie, setTie] = useState(false),
        [rank, setRank] = useState<number | null>(null),
        [totalPlayers, setTotalPlayers] = useState(0);

    useEffect(() => {
        if (!showCalculating && loading && rankingScore) {
            (async () => {
                try {
                    let totalRanked = 0,
                        totalAggregated = 0,
                        key = divisionBirthYear.toString(),
                        players = firebase.db.collection('players').where('divisionBirthYear', '==', divisionBirthYear),
                        playerScoreUpdatedAt = player.rankingScoreUpdatedAt ? player.rankingScoreUpdatedAt.toDate() : null;

                    if (state) {
                        players = players.where('state', '==', state);
                    }
                    
                    if (position) {
                        key += `_${position}`;
                        players = players.where('positions', 'array-contains', position);
                    }
                    
                    if (state) {
                        key += `_${state}`;
                    }

                    players = players.where('rankingScore','<', Math.floor((rankingScore + SCORE_AGGREGATE_BUCKET_SIZE) / SCORE_AGGREGATE_BUCKET_SIZE) * SCORE_AGGREGATE_BUCKET_SIZE).where('rankingScore', '>=', rankingScore);

                    const res = await Promise.all([
                        firebase.db.collection('aggregates').doc(key).get(),
                        players.get()
                    ]);
                            
                    if (res[0].data()) {
                        const updatedAt = res[0].data().updatedAt ? res[0].data().updatedAt.toDate() : null;

                        if (updatedAt && playerScoreUpdatedAt && playerScoreUpdatedAt > updatedAt) {
                            setShowCalculating(true);
                            return false;
                        }


                        const scores = verified ? res[0].data().verifiedRankingScores : res[0].data().rankingScores;
                        
                        totalAggregated = res[0].data().totalPlayers;
                        Object.keys(scores).filter(s => parseInt(s) > rankingScore).forEach(s => {
                            totalRanked += scores[s];
                        });
                    }

                    if (res[1]) {
                        const sameScore = res[1].docs.filter((d: FirebaseTypes.firestore.DocumentSnapshot) => (
                            (d?.data() as Player).id !== player.id
                            && (d?.data() as Player).rankingScore === rankingScore
                            && (verified ? !!(d?.data() as Player)[verifiedField] : true)
                        )).length;
                        if (sameScore > 0) {
                            setTie(true);
                        }
                        totalRanked += res[1].docs.filter((d: FirebaseTypes.firestore.DocumentSnapshot) => {
                            if (!d) {
                                return false;
                            }
                            const p = d.data() as Player;
                            return p.rankingScore && p.rankingScore > rankingScore && (verified ? !!p[verifiedField] : true);
                        }).length;
                    }


                    setRank(totalRanked + 1);
                    setTotalPlayers(totalAggregated);

                    setLoading(false);
                } catch (e) {
                    setLoading(false);
                    console.log('Fetch players error', e);
                }
            })();
        }
    }, [setRank, firebase, divisionBirthYear, state, position, rankingScore, verified, verifiedField, loading, showCalculating, setShowCalculating, setLoading, setTie, player]);

    if (showCalculating) {
        return <CircularProgress color="inherit" size={24} />;
    }

    if (loading) {
        return null;
    }

    if (verified && rank) {
        return <>{tie && 'T'}{rank}</>;
    }

    if (rank && !!totalPlayers) {
        if (rank === 1) {
            return <>99%</>
        }
        return <>{Math.max(1, Math.min(Math.round(((totalPlayers - rank) / totalPlayers) * 100), 99))}%</>
    }

    return <>--</>;
};

export default Ranking;