import React, { FunctionComponent, useContext, useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Typography, TextField, Button, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FirebaseContext from '../contexts/FirebaseContext';
import { ReactComponent as GoogleLogo } from '../images/svg/google.svg';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(1),
        width: 300,
        textAlign: 'center'
    },
    googleSignIn: {
        textTransform: 'none'
    },
    or: {
        margin: theme.spacing(2),
        fontWeight: 'normal'
    },
    alert: {
        marginTop: theme.spacing()
    },
    signupLink: {
        marginTop: theme.spacing(2)
    }
}));

const SignIn: FunctionComponent = () => {
    const classes = useStyles(),
        history = useHistory(),
        { state } = useLocation<{ redirect: string }>(),
        firebase = useContext(FirebaseContext),
        [error, setError] = useState<string | null>(null),
        [creds, setCreds] = useState<{ email: string, password: string }>({ email: '', password: '' });

    return (
        <div className={classes.root}>
            <Typography variant="h5" paragraph>User Login</Typography>
            <TextField
                label="Email"
                size="small"
                variant="outlined"
                margin="normal"
                fullWidth
                placeholder="user@email.com"
                value={creds.email}
                onChange={e => setCreds({ email: e.target.value, password: creds.password })}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                label="Password"
                size="small"
                margin="normal"
                variant="outlined"
                fullWidth
                placeholder="password"
                type="password"
                value={creds.password}
                onChange={e => setCreds({ email: creds.email, password: e.target.value })}
                InputLabelProps={{ shrink: true }}
            />
            <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                disabled={!creds.email || !creds.password}
                onClick={async () => {
                    try {
                        await firebase.auth().signInWithEmailAndPassword(creds.email, creds.password);
                        history.push(state?.redirect || '/profile/players');
                    } catch (e) {
                        setError(e.message);
                    }
                }}
            >
                Continue
            </Button>
            <Typography variant="caption" display="block" align="center"><Link to="/forgot-password">Forgot Password</Link></Typography>
            {error && <Alert icon={false} className={classes.alert} severity="error">{error}</Alert>}
            <Typography className={classes.or} variant="subtitle2">or</Typography>
            <Button
                variant="outlined"
                fullWidth
                startIcon={<GoogleLogo />}
                onClick={async () => {
                    try {
                        await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
                        history.push(state?.redirect || '/profile/players');
                    } catch (e) {
                        setError(`There was an issue signing in. ${e.message}`);
                    }

                }}
            >
                <Typography className={classes.googleSignIn}>Continue with Google</Typography>
            </Button>
            <Typography className={classes.signupLink} variant="caption" display="block" align="center">New to Yiketty? <Link to="/signup">Sign up for free!</Link></Typography>
        </div>
    );
};

export default SignIn;
