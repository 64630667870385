import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2'
import { useTheme } from '@material-ui/core';
import Player from 'src/@types/Player';
import Velocity from 'src/@types/Velocity';

const VelocityChart: React.FC<{ player: Player, category: string, velocities: Velocity[] | undefined, onClick?(velocity: Velocity): any }> = ({ player, category, velocities, onClick }) => {
    const theme = useTheme(),
        chartRef = useRef() as React.MutableRefObject<Line>;
        
    if (!velocities) {
        return null;
    }

    return (
        <Line
            ref={chartRef}
            options={{
                datalabels: {
                    display: false
                },
                onHover: (e: MouseEvent) => {
                    const elements = chartRef.current.chartInstance.getElementAtEvent(e);

                    if (onClick && elements.length > 0) {
                        const veloCreatedAt = chartRef.current.chartInstance.data.labels[elements[0]._index];

                        if (veloCreatedAt && e.target) {
                            const target = e.target as HTMLElement;
                            target.style.cursor = 'pointer';
                        }
                    }
                },
                onClick: (e: MouseEvent) => {   
                    const elements = chartRef.current.chartInstance.getElementAtEvent(e);

                    if (onClick && elements.length > 0) {
                        const veloCreatedAt = chartRef.current.chartInstance.data.labels[elements[0]._index],
                            velo = velocities.find(v => v.createdAt.toDate().toISOString() === veloCreatedAt.toISOString());
                        
                        if (velo) {
                            return onClick(velo);
                        }
                    }
                    
                },
                layout: {
                    padding: {
                        left: theme.spacing(4),
                        right: theme.spacing(4),
                        top: theme.spacing(2),
                        bottom: theme.spacing(2)
                    }
                },
                animation: {
                    duration: 0
                },
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        type: 'time',
                        distribution: 'linear',
                        time: {
                            tooltipFormat: 'MMMM D, YYYY'
                        },
                        ticks: {
                            source: 'labels'
                        },
                        gridLines: {
                            display: false
                        }
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'MPH',
                            fontStyle: 'bold'
                        },
                        ticks: {
                            min: Math.floor(Math.min(...(velocities.map(v => v.value as number))) * 0.8 / 5) * 5,
                            max: Math.ceil(Math.max(...(velocities.map(v => v.value as number))) * 1.1 / 5) * 5
                        }
                    }, {
                        id: 'background-axis',
                        display: false,
                        ticks: {
                            min: Math.floor(Math.min(...(velocities.map(v => v.value as number))) * 0.8 / 5) * 5,
                            max: Math.ceil(Math.max(...(velocities.map(v => v.value as number))) * 1.1 / 5) * 5
                        },
                        stacked: false
                    }]
                }
            }}
            data={{
                labels: velocities.map(v => v.createdAt.toDate()),
                datasets: [{
                    label: `${category === 'exitVelocity' ? 'Exit' : 'Fastball'} Velocity`,
                    data: velocities.map(v => v.value),
                    fill: false,
                    borderWidth: 6,
                    lineTension: 0,
                    borderColor: theme.palette.primary.main,
                    datalabels: {
                        display: false
                    }
                },/* {
					yAxisID: 'background-axis',
					label: 'background 1',
					data: velocities.map(() => 50),
					pointRadius: 0,
					borderWidth: 0,
                    backgroundColor: 'rgb(253,153,37,0.1)',
                    fill: 'origin'
				}, {
					yAxisID: 'background-axis',
					label: 'background 2',
					data: velocities.map(() => 10),
					pointRadius: 0,
					borderWidth: 0,
                    backgroundColor: 'rgb(147,207,87,0.1)'
				},*/ { 
					yAxisID: 'background-axis',
					label: 'background 3',
					data: velocities.map(() => Math.ceil(Math.max(...(velocities.map(v => v.value as number))) * 1.1 / 5) * 5),
					pointRadius: 0,
                    borderWidth: 0,
                    datalabels: {
                        display: false
                    },
                    backgroundColor: 'rgb(147,207,87,0.1)'
                    //backgroundColor: 'rgb(67,160,71,0.1)'
                }]
            }}
        />
    );
};

export default VelocityChart;