import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FirebaseContext from '../contexts/FirebaseContext';
import Player from 'src/@types/Player';
import PlayerDetail from '../components/PlayerDetail/PlayerDetail';
import PageHero from '../components/PageHero';
import BaseballTeamImage from '../images/baseball_team.jpg';

const PlayerPage: FunctionComponent = () => {
    const { playerId } = useParams<{playerId: string}>(),
        firebase = useContext(FirebaseContext),
        [player, setPlayer] = useState<Player | null>(null);

    useEffect(() => {
        if (playerId && !player) {
            (async () => {
                const doc = await firebase.db.collection('players').doc(playerId).get();
                if (doc && doc.data()) {
                    setPlayer(doc.data() as Player);
                }
            })();
        }
    }, [player, firebase, playerId, setPlayer]);

    return (
        <>
            <PageHero height={300} background={BaseballTeamImage} />
            {player && <PlayerDetail player={player} onUpdate={(updated: Player) => setPlayer(updated)} />}
        </>
    );
};

export default PlayerPage;