import React, { FunctionComponent, useContext, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import FirebaseApp from 'firebase/app';
import { makeStyles, Typography, TextField, Button, InputLabel, FormControl, Select, MenuItem, LinearProgress, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { DropzoneArea } from 'material-ui-dropzone';
import FirebaseContext from '../../contexts/FirebaseContext';
import Player from 'src/@types/Player';
import Velocity from 'src/@types/Velocity';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const MAX_VELOCITY = 100;

const useStyles = makeStyles(theme => ({
    formControl: {
        marginBottom: theme.spacing(2)
    },
    dropzone: {
        minHeight: 150
    },
    dropzoneText: {
        fontSize: '1.0rem'
    },
    deleteButton: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.error.main
        }
    }
}));

type VelocityInput = {
    id?: string,
    category: string,
    createdAt: FirebaseApp.firestore.Timestamp,
    value: string | number | null,
    verified?: boolean
};

const LogVelocity: FunctionComponent<{ player: Player, open?: boolean, velocity: Velocity | null | undefined, onClose(): any, onSave?(velocity: Velocity): any, onDelete?(id: string | undefined): any}> = ({ player, velocity, open, onClose, onSave, onDelete }) => {
    const firebase = useContext(FirebaseContext),
        classes = useStyles(),
        [deleting, setDeleting] = useState(false),
        [error, setError] = useState<string | null>(null),
        [verification, setVerification] = useState<File | null>(null),
        [progress, setProgress] = useState(0),
        [newVelocity, setNewVelocity] = useState<VelocityInput>(velocity || {
            category: 'exitVelocity',
            createdAt: FirebaseApp.firestore.Timestamp.fromDate(new Date()),
            value: null
        });

    return (
        <Dialog open={open || false} onClose={onClose}>
            <DialogTitle>{velocity ? 'Edit' : 'Log'} Velocity</DialogTitle>
            <DialogContent>
                <FormControl fullWidth className={classes.formControl}>
                    <InputLabel required id="category_label">Category</InputLabel>
                    <Select required labelId="category_label" value={newVelocity.category} onChange={e => setNewVelocity({ ...newVelocity, category: e.target.value as string })}>
                        <MenuItem value="exitVelocity">Exit Velocity</MenuItem>
                        <MenuItem value="fastballVelocity">Fastball Velocity</MenuItem>
                    </Select>
                </FormControl>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        className={classes.formControl}
                        disableToolbar
                        variant="inline"
                        value={newVelocity.createdAt.toDate()}
                        format="MM/dd/yyyy"
                        onChange={(logDate: MaterialUiPickersDate) => setNewVelocity({ ...newVelocity, createdAt: (logDate ? FirebaseApp.firestore.Timestamp.fromDate(logDate) : newVelocity.createdAt) })}
                        label="Measurement Date"
                        required
                    />
                </MuiPickersUtilsProvider>
                <TextField
                    className={classes.formControl}
                    fullWidth
                    required
                    label="Measured speed (mph)"
                    value={newVelocity.value ? newVelocity.value.toString() : ''}
                    onChange={e => setNewVelocity({ ...newVelocity, value: e.target.value, verified: false })}
                />
                {!newVelocity.verified && (
                    <div className={classes.formControl}>
                        {progress === 0 ? (
                            <DropzoneArea
                                dropzoneText="Drag and drop, or click, to upload a picture or video of the exit or fastball velocity measurement for verification. This is optional, but doing so will change your percentile to an exact rank and allow you to be considered for the leaderboard."
                                dropzoneParagraphClass={classes.dropzoneText}
                                dropzoneClass={classes.dropzone}
                                maxFileSize={10000000}
                                acceptedFiles={['image/*', 'video/*']}
                                filesLimit={1}
                                onChange={files => setVerification(files.length ? files[0] : null)}
                            />
                        ) : (
                            <LinearProgress variant="determinate" value={progress} />
                        )}
                    </div>
                )}
                {error && <Alert severity="error">{error}</Alert>}
            </DialogContent>
            <DialogActions>
                {deleting && velocity?.id ? (
                    <>
                        <div style={{ flex: 1 }}>
                            <Typography variant="subtitle2">Are you sure you want to delete this velocity?</Typography>
                        </div>
                        <Button onClick={() => setDeleting(false)} size="small">Cancel</Button>
                        <Button
                            size="small"
                            className={classes.deleteButton}
                            onClick={async () => {
                                await firebase.db.collection('players').doc(player.id).collection('velocities').doc(velocity.id).delete();

                                if (onDelete) {
                                    onDelete(velocity.id);
                                }
                                return onClose();
                            }}
                        >
                            Delete
                        </Button>
                    </>
               ) : (
                    <>
                        {velocity?.id && (
                            <div style={{ flex: 1 }}><Button size="small" onClick={() => setDeleting(true)}>Delete</Button></div>
                        )}
                        <Button size="small" onClick={onClose}>Cancel</Button>
                        <Button
                            size="small"
                            disabled={!newVelocity.value || newVelocity.value > MAX_VELOCITY || newVelocity.value < 1}
                            onClick={async () => {
                                const doc = firebase.db.collection('players').doc(player.id).collection('velocities').doc(newVelocity.id);
                                await doc.set({
                                    id: doc.id,
                                    ...newVelocity,
                                    value: parseFloat(newVelocity.value as string || '')
                                });
                                const saved = await doc.get();

                                if(verification) {
                                    const fileNameSplit = verification?.name.split('.'),
                                        ref = firebase.storage.ref(`velocity_verification/${player.id}_${saved.data().id}.${fileNameSplit[fileNameSplit.length - 1]}`),
                                        uploadTask = ref.put(verification);

                                    uploadTask.on('state_changed', (snap: FirebaseApp.storage.UploadTaskSnapshot) => {
                                        setProgress((snap.bytesTransferred / snap.totalBytes) * 100);
                                    }, (error: Error) => {
                                        setError(error.message);
                                        setProgress(0);
                                    }, async () => {
                                        const newVelo = saved.data();
                                        setProgress(0);
                                        setVerification(null);
                                        if (onSave) {
                                            onSave({
                                                ...newVelo,
                                                createdAt: newVelo.createdAt
                                            });
                                        }
                                        return onClose();
                                    });
                                } else {
                                    if (onSave) {
                                        const newVelo = saved.data();
                                        onSave({
                                            ...newVelo,
                                            createdAt: newVelo.createdAt
                                        });
                                    }
                                    return onClose();
                                }
                            }}
                        >
                            Continue
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default LogVelocity;